// Spin animation
@-webkit-keyframes lightcase-spin {

	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		transform: rotate(359deg);
	}

}

@-moz-keyframes lightcase-spin {

	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		transform: rotate(359deg);
	}

}

@-o-keyframes lightcase-spin {

	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		transform: rotate(359deg);
	}

}

@-ms-keyframes lightcase-spin {

	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		transform: rotate(359deg);
	}

}

@keyframes lightcase-spin {

	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		transform: rotate(359deg);
	}

}