@import '../variables';
.accomodation-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.accomodation-item {
		position: relative;
		width: 100%;
		max-width: 100%;
		margin: 0 10px 40px;
		display: flex;
		border-radius: 10px;
		background-color: #fff;
		transition: all 250ms ease-in-out;
		&:hover {
			transform: translate(0, -10px);
			box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .16);
		}
		.image {
			width: 35%;
			margin-right: 15px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 10px 0 0 10px;
			}

			&.--hive-image {
				width: 20%;
			}

		}
		&.api-acco {
			&:before {
				content: "API";
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-weight: 700;
				font-size: 20px;
				right: 0;
				top: -60px;
				width: 120px;
				height: 120px;
				background-image: url(../img/akce.svg);
				background-position: 50% 7px;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
		.desc {
			width: 65%;
			padding: 30px 15px;
			margin: auto;
			h3 {
				font-weight: 700;
				font-size: 2.3rem;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}
.accomodation-wrapper-detail {
	display: flex;
	.image {
		width: 40%;
		margin-right: 30px;
		img {
			object-fit: cover;
		}
	}
	.desc {
		width: 60%;
		h2 {
			text-align: left;
			&::before {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				transform: unset;
				width: 100px;
				height: 3px;
				border-radius: 30px;
				background-color: $base-color;
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				opacity: 0.6;
				color: $sec-color;
				line-height: 1.4;
				position: relative;
				margin-bottom: 8px;
			}
		}
	}
}
@media (max-width: $bp-md - 1) {
	.accomodation-wrapper {
		display: flex;
		flex-wrap: wrap;
		.accomodation-item {
			width: 100%;
			margin-right: 0;
			margin-bottom: 30px;
			.desc {
				div {
					margin-bottom: 15px;
				}
				a {
					margin: unset;
				}
			}
		}
	}
}
@media (max-width: $bp-sm - 1) {
	.accomodation-wrapper-detail {
		display: flex;
		flex-wrap: wrap;
		.image {
			width: 100%;
			margin-right: 0;
			margin-bottom: 30px;
			img {
				object-fit: cover;
			}
		}
		.desc {
			width: 100%;
			h2 {
				text-align: left;
				&::before {
					position: absolute;
					content: "";
					bottom: 0;
					left: 0;
					transform: unset;
					width: 100px;
					height: 3px;
					border-radius: 30px;
					background-color: $base-color;
				}
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				li {
					width: 50%;
					opacity: 0.6;
					color: $sec-color;
					line-height: 1.4;
					padding-left: 15px;
					position: relative;
					&::before {
						position: absolute;
						content: "";
						top: 50%;
						left: 0;
						width: 10px;
						height: 10px;
						background-color: $base-color;
						border-radius: 50%;
						transform: translate(0, -50%);
					}
				}
			}
		}
	}
}
@media (max-width: $bp-xs - 1) {
	.accomodation-wrapper-detail {
		.desc {
			ul {
				display: flex;
				flex-wrap: wrap;
				li {
					width: 100%;
				}
			}
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.accomodation-wrapper {
		display: flex;
		flex-wrap: wrap;
		.accomodation-item {
			flex-wrap: wrap;

			margin-left: 0;
			.image {
				width: 100%;
				margin-right: 0;

				&.--hive-image {
					width: 100% !important;
				}

				img {
					height: 200px;
					width: 100%;
					border-radius: 10px 10px 0 0;
				}
			}
			.desc {
				width: 100%;
				div {
					margin-bottom: 15px;
				}
				a {
					margin: unset;
				}
			}
		}
	}
}
.booking-link {
	width: 700px;
	max-width: 100%;
	margin: 32px auto 0;
	padding: 40px;
	border: 2px solid $base-color;
	border-radius: 10px;
	background-color: #fff;
	text-align: center;
}
.booking-rating {
	display: table;
	margin: 0 auto 32px;
	padding: 20px;
	margin-bottom: 30px;
	background: #fff;
	text-align: center;
	border-radius: 10px;
	width: 700px;
	max-width: 100%;
	span.booking-int {
		display: inline-block;
		padding: 10px;
		margin: 5px 10px;
		border-radius: 8px;
		border-bottom-left-radius: 0;
		background-color: #003580;
		color: #fff;
		font-size: 2rem;
	}
	strong {
		color: #003580;
	}
}
