@import '../variables';

.product-wrapper {
	display: flex;
	flex-wrap: wrap;
	.product-item {
		width: calc(50% - 25px);
		margin-right: 25px;
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 10px;
		padding: 40px 20px;
		transition: all 250ms ease-in-out;
		&.--api{
			width: 100%;
			h3{
				font-size:3.8rem;
				@media (max-width: $bp-xs - 1) {
					font-size: 2.8rem;
				}
			}
			p{
				font-size: 2.2rem;
				@media (max-width: $bp-xs - 1) {
					font-size: 1.6rem;
				}
			}
			.product-image{
				width: 320px;
				flex-shrink: 0;
				img{
					object-fit: cover;
					border-radius: 10px;
				}
			}
		}
		&:hover {
			transform: translate(0, -10px);
			box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .16);
		}
		.product-image {
			display: inline-block;
			width: 140px;
			height: 150px;
			position: relative;
			max-width: 100%;
			margin-right: 20px;
			@media (max-width: $bp-xs - 1) {
				margin-right: 0;
			}
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: contain;
			}
		}
		.product-desc {
			width: calc(100% - 140px);
			h3 {
				color: $sec-color;
				font-weight: 700;
			}
			a {
				padding-right: 50px;
				display: table;
				margin: 0 0 0 auto;
				position: relative;
				font-weight: 700;

				&::before {
					position: absolute;
					content: "";
					width: 20px;
					height: 20px;
					background-image: url("../img/arrow-right.svg");
					right: 25px;
					top: 50%;
					transform: translate(0, -50%);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					transition: all 250ms ease-in-out;
				}
				&:hover {
					&::before {
						right: 20px;
					}
				}
			}
		}
	}
}
.product-news-wrapper {
	width: 55%;
	margin: 60px auto 0;
	background-color: #fff;
	position: relative;
	border-radius: 10px;
	padding: 30px 60px 30px 110px;
	border: 3px solid $base-color;
	&::before {
		content: "Akce";
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: 700;
		font-size: 20px;
		right: -60px;
		top: -60px;
		width: 120px;
		height: 120px;
		background-image: url(../img/akce.svg);
		background-position: 50% 7px;
		background-repeat: no-repeat;
		background-size: contain;
	}
	h2 {
		text-align: left;
		margin-bottom: 10px;
		padding-bottom: 0;
		&::before {
			display: none;
		}
	}
	img {
		width: 200px;
		height: auto;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.action-price {
		position: relative;
		margin-top: 8px;
		.sale-count {
			position: absolute;
			bottom: calc(100% - 4px);
			left: 0;
			font-size: 1.1rem;
			display: inline-block;
			background-color: #ff6969;
			border-radius: 8px;
			color: #fff;
			padding: 3px 6px;
			position: absolute;
			//text-decoration: line-through;
			font-weight: 400;
		}
		.new-price {
			font-weight: 700;
			font-size: 3rem;
			margin-right: 10px;
			display: inline-block;
		}
		.original-price {
			color: rgba(0, 0, 0, .36);
			text-decoration: line-through;
		}
	}
}
.product-detail-wrapper {
	display: flex;
	.image {
		width: 40%;
		margin-right: 50px;
		padding: 40px;
		background-color: #fff;
		border-radius: 10px;
		height: 380px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.desc {
		&.api-desc {
			width: 100%;
			strong {
				display: inline-block;
				margin-bottom: 0;
			}
		}
		width: 60%;
		h2 {
			text-align: left;
			&::before {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				transform: translate(0, 0);
				width: 100px;
				height: 3px;
				background-color: $base-color;
				border-radius: 30px;
			}
		}
		div {
			// strong {
			// 	display: block;
			// 	margin-bottom: 10px;
			// }
			li {
				color: $sec-color;
			}
			p {
				margin-bottom: 10px;
			}
		}
	}
}
@media (max-width: $bp-md - 1) {
	.product-detail-wrapper {
		display: flex;
		.image {
			width: 45%;
		}
		.desc {
			width: 55%;
		}
	}
}
@media (max-width: $bp-md - 1) {
	.product-detail-wrapper {
		display: flex;
		flex-wrap: wrap;
		.image {
			width: 100%;
			margin-bottom: 40px;
			margin-right: 0;
		}
		.desc {
			width: 100%;
		}
	}
}
@media (max-width: $bp-sm - 1) {
	.product-wrapper {
		.product-item {
			width: 100%;
			margin-right: 0;
		}
	}
	.product-news-wrapper {
		width: 80%;
		padding: 30px 10px 30px 70px;

		img {
			width: 140px;
		}
	}
}
@media (max-width: $bp-xs - 1) {
	.product-wrapper {
		.product-item {
			width: 100%;
			margin-right: 0;
			flex-direction: column;
			.product-image {
				margin-bottom: 20px;
				height: 150px;
			}
			.product-desc {
				width: 100%;
			}
		}
	}
	.product-news-wrapper {
		width: 100%;
		margin-top: 60px;
		margin-bottom: 0;
		padding: 70px 15px 30px 15px;
		img {
			left: 0;
			top: 0;
			transform: translate(0%, -70px);
		}
		&:before {
			right: 0;
		}
	}
}
// Products HP arrow
.slick-arrows-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}
.slick-dots {
	list-style: none !important;
}
.slick-arrow {
	position: relative;
	width: 50px;
	height: 50px;
	background-color: $base-color;
	border-radius: 5px;
	margin: 5px;
	cursor: pointer;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../img/slick-left.svg');
		background-position: center;
		background-size: 50%;
		background-repeat: no-repeat;
	}
	&.arrow-next {
		&:before {
			background-image: url('../img/slick-right.svg');
		}
	}
}
ul.slick-dots {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	& > li button {
		display: block;
		background-color: #fff;
		font-size: 0;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		margin: 0 5px;
		border: 2px solid $base-color;
	}
	li.slick-active button {
		background-color: $base-color;
		transform: scale(1.1);
	}
}

.category-products-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.category-product-item {
		// &.api-item {
		// 	width: calc(50% - 20px);
		// 	@media (max-width: $bp-xs - 1) {
		// 		width: 100%;
		// 	}
		// 	display: flex;
		// 	flex-direction: column;
		// 	justify-content: center;
		// 	align-items: center;
		// }
		//width: calc(25% - 20px);
		width: 300px;
		margin: 10px;
		border-radius: 10px;
		background-color: #fff;
		padding: 20px 10px;
		transition: all 250ms ease-in-out;
		&.not-available {
			opacity: 0.7;
			h3 {
				text-decoration: line-through;
			}
			.product-price {
				color: #dc3545;
			}
		}
		.product-price {
			color: #000;
			font-weight: 500;
		}
		.product-desc {
			text-align: center;
		}
		.product-image {
			display: flex;
			img {
				height: 160px;
				width: auto;
				margin: 0 auto;
				max-width: 100%;
			}
		}
		h3 {
			font-size: 2rem;
		}
		&:hover {
			transform: translate(0, -10px);
			box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .16);
		}
	}
}
.product-tabs {
	display: flex;
	flex-wrap: wrap;
	.tab {
		padding: 15px 18px;
		font-weight: 700;
		font-size: 1.8rem;
		margin-right: 6px;
		transition: all 250ms ease-in-out;
		cursor: pointer;
		&.tab-active {
			background-color: #fff;
			box-shadow: inset 0 4px 0 0 $base-color;
		}
		&:hover {
			box-shadow: inset 0 4px 0 0 $base-color;
		}
	}
}
.tab-content {
	display: none;
	padding: 20px;
	background-color: #fff;
  margin-bottom: 32px;
	h2 {
		font-size: 3rem;
		padding-bottom: 16px;
	}
	&.tab-active {
		display: block;
	}
}
@media (max-width: $bp-xxs - 1) {
	.product-tabs {
		.tab {
			width: 100%;
			margin-right: 0;
		}
	}
}

// New products

.box-wrapper {
	display: flex;
	flex-wrap: wrap;
}
.product-item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 16px 15px;
    transition: all 250ms ease-in-out;

    &:hover {
        transform: translate(0, -10px);
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .16);
    }
}

.category-products-list-n {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 60px;

    .product-item {
        flex-direction: column;

        @media (min-width: $bp-xxs) {
            width: calc(50% - 10px);
        }

        @media (min-width: $bp-xs) {
            width: calc(33% - 11px);
        }

        @media (min-width: $bp-md) {
            width: calc(25% - 15px);
        }

        @media (min-width: $bp-lg) {
            width: calc(20% - 16px);
        }

        h3 {
            text-align: center;
            font-size: 18px;
        }
    }

    .product-desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }
    .product-image {
        background-color: $base-color;
        width: 80%;
        padding-top: 80%;
        border-radius: 50%;
        margin-bottom: 20px;
        display: flex;
        // overflow: hidden;
        position: relative;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: contain;
            object-position: center;
        }
    }
}

.products-category {
    padding-left: 20px;
    border-left: 3px solid $base-color;
}

.category-product-item.api-item {
	padding: 20px;
	border-left: 4px solid $base-color;

	.btn-std {
		margin-left: 0;
	}

	* {
		text-align: left;
	}
}
.food-for-bees{
  .product-image{
    overflow: hidden;
    img{
      width: 100%;
    }
  }
}
