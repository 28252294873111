@import "../_variables";

body {
	//padding-top: $nav-height;
	padding-top: 0;
}
#mainMenu.toFixed {
	position: fixed;
	background-color: #5a4524;
	animation: slide-down 500ms;
	box-shadow: 0 11px 38px 0 rgba(0, 0, 0, 0.4);
	transition: all 250ms ease-in-out;
	#header-bottom-line {
		position: relative;
		padding-bottom: 10px;
		&::before {
			display: none;
		}
	}
	nav > ul {
		color: $base-color;
	}
	.frequent_links {
		.icon-header:after {
			filter: none;
		}
	}
	.logo img {
		filter: none;
	}
	.nav-inner {
		padding: 10px 0;
	}
}
// Full header
header#mainMenu {
	position: absolute;
	display: flex;
	background: $nav-bg;
	background-color: transparent;
	width: 100%;
	top: 0;
	left: 0;
	padding: 10px 0;
	height: $nav-height;
	z-index: 999;
	& > .container {
		margin: auto;
	}
}
#header-bottom-line {
	position: relative;
	padding-bottom: 10px;
	&::before {
		position: absolute;
		content: "";
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #fff;
	}
}
.header-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	label.hamburger {
		z-index: 9999;
		cursor: pointer;
		width: 40px;
		height: 50px;
		margin-right: 0;
		margin-left: auto;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		input {
			display: none;
		}
		span {
			display: inline-block;
			width: 40px;
			height: 4px;
			border-radius: 3px;
			background: #fff;
			margin: 4px 0 4px auto;
			transition: all 200ms ease-in-out;
			&:nth-child(3) {
				width: 30px;
			}
		}
		&:hover span:nth-child(3) {
			width: 40px;
		}
	}
	.logo {
		margin: 0 auto 0 0;
	}
	nav {
		ul.navigation {
			display: flex;
			align-items: center;
			li {
				position: relative;
				margin-left: 30px;
				@media screen and (max-width: $bp-lg - 1) {
					margin-left: 20px;
				}
				a {
					display: inline-block;
					position: relative;
					padding: 30px 0px;
					color: $nav-link-color;
					font-weight: 700;
					&:before {
						content: "";
						position: absolute;
						display: inline-block;
						top: 100%;
						left: 50%;
						height: 4px;
						width: 0px;
						transform: translate(-50%, -20px);
						background-color: $base-color;
						transition: all 200ms ease-in-out;
						border-radius: 5px;
					}
					&:hover {
						color: $nav-link-color-hover;
						&:before {
							width: 50px;
						}
					}
					&.active {
						color: $nav-link-color-hover;
						&:before {
							width: 50px;
						}
					}
					&.--api{
						text-transform: capitalize;
					}
					&.header-main-btn {
						position: relative;
						color: #fff !important;
						background: $base-color;
						border-radius: 9px;
						padding: 14px 30px;
						margin-left: 20px;
						&:hover {
							text-shadow: 0 0 10px black;
						}
						span {
							position: relative;
							z-index: 5;
						}
					}
				}
			}
		}
	}
	.logo {
		img {
			height: 70px;
			width: auto;

			transition: all 350ms ease-in-out;
		}
		@media (max-width: $bp-lg - 1) {
			img {
				height: 50px;
			}
		}
	}
}
@media (min-width: $nav-bp) {
	.header-wrapper {
		.hamburger {
			display: none;
		}
	}
	ul.navigation {
		display: flex;
		li {
			.submenu {
				position: absolute;
				top: 100%;
				background: $nav-bg;
				height: auto;
				left: 0;
				box-shadow: 0 15px 10px rgba(0, 0, 0, 0.11);
				z-index: 5;
				transition: all 400ms ease-in-out;
				max-height: 0;
				opacity: 0;
				overflow: hidden;
				li a {
					padding: 20px;
					white-space: nowrap;
				}
			}
			&:last-child {
				.submenu {
					right: 0;
					left: auto;
				}
			}
			&:hover > .submenu {
				opacity: 1;
				max-height: 500px;
			}
		}
	}
}
@media (max-width: $nav-bp - 1) {
	header#mainMenu {
		label.hamburger {
			display: flex;
			// Chage order of hamburger ang lang select
			order: 10;
			margin: 0 0 0 20px;
		}
		nav {
			position: fixed;
			left: 0;
			top: 0;
			background: $nav-bg;
			z-index: 999;
			width: 100%;
			min-height: 100vh;
			height: 100%;
			padding: $nav-height 10px 40px 10px;
			overflow: auto;
			opacity: 0;
			display: none;
			transition: all 300ms ease-in-out;

			ul.navigation {
				flex-direction: column;
				height: auto;
				li {
					margin-left: 0;
					ul.submenu {
						max-height: 0;
						height: auto;
						padding-left: 20px;
						overflow: hidden;
						transition: all 300ms ease-in-out;
					}
					a {
						width: 100%;
						padding: 12px 20px;
						&::before {
							top: 125%;
						}
					}
					&:focus ul.submenu,
					&:hover ul.submenu {
						max-height: 500px;
					}
				}
			}
		}
	}
	body.menuOpen {
		overflow: hidden;
		header#mainMenu nav {
			opacity: 1;
			display: block;
		}
	}
}

// Changes after menu is opened
@media (max-width: $nav-bp) {
	body.menuOpen {
		.header-wrapper .logo img {
			filter: none;
		}
		.header-wrapper .logo {
			position: relative;
			z-index: 1000;
		}
		header#mainMenu nav {
			background-color: #fff;
			padding-top: 100px;
			li a {
				color: #333;
				font-size: 22px;
				font-weight: 700;
				text-align: center;
				padding: 12px 20px;
				width: 100%;
				margin: 0;
			}
		}
		.hamburger {
			span {
				background-color: #333;
			}
			span:nth-child(3) {
				width: 0;
				opacity: 0;
			}
			span:nth-child(2) {
				transform: rotate(45deg) translate(10px, 6px);
			}
			span:nth-child(4) {
				transform: rotate(-45deg) translate(11px, -8px);
			}
		}
	}
}
