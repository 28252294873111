@import '../variables';
.courses-info-wrapper {
	display: flex;
	.courses-info-item {
		background-color: #fff;
		padding: 30px 20px;
		width: 25%;
		margin-right: 10px;
		img {
			display: block;
			margin: auto;
			width: auto;
			height: 140px;
			margin-bottom: 40px;
		}
		h3 {
			text-align: center;
			font-weight: 700;
		}
		p {
			text-align: center;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
.courses-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
	.courses-info-item {
		width: calc(50% - 20px);
		margin-right: 20px;
		margin-bottom: 20px;
		background-color: #fff;
		border-radius: 10px;
		display: flex;
		img {
			width: 25%;
			height: 100%;
			@media (max-width: $bp-xs - 1) {
				height: 200px;
			}
			border-radius: 10px 0 0 10px;
		}
		.course-desc {
			width: 75%;
			padding: 30px 20px;
			h3 {
				font-weight: 700;
				margin-bottom: 8px;
			}
			p {
				margin-bottom: 10px;
			}
			a {
				padding-right: 50px;
				display: table;
				margin: 0 0 0 auto;
				position: relative;
				font-weight: 700;

				&::before {
					position: absolute;
					content: "";
					width: 20px;
					height: 20px;
					background-image: url("../img/arrow-right.svg");
					right: 25px;
					top: 50%;
					transform: translate(0, -50%);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					transition: all 250ms ease-in-out;
				}
				&:hover {
					&::before {
						right: 20px;
					}
				}
			}
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
}
.course-detail-wrapper {
	display: flex;
	.image {
		width: 35%;
		margin-right: 40px;
		img {
			border-radius: 10px;
			width: 100%;
		}
	}
	.desc {
		width: 65%;
		h2 {
			text-align: left;
			&::before {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				transform: unset;
				width: 100px;
				height: 3px;
				border-radius: 30px;
				background-color: $base-color;
			}
		}
		.course-content-price {
			display: flex;
			margin-bottom: 40px;
			.bold {
				font-weight: 700;
				font-size: 2rem;
				margin-bottom: 20px;
			}
			.course-content {
				width: 50%;
				margin-right: 30px;
			}
		}
		.course-price {
			display: flex;
			.price {
				font-size: 3rem;
			}
			.bold {
				font-weight: 700;
				font-size: 3rem;
				margin-bottom: 20px;
			}
		}
		.content {
			background-color: #fff;
			display: flex;
			flex-direction: column;
			border-radius: 10px;

			div {
				padding: 20px;
				border-bottom: 1px solid $sec-color;
				&:last-child {
					border-bottom: unset;
				}
			}
		}
	}
}

.courses-wrapper {
	.courses-info-item {
		transition: all 250ms ease-in-out;
		&:hover {
			transform: translate(0, -10px);
			box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .16);
		}
	}
}

@media (max-width: $bp-md - 1) {
	.courses-wrapper {
		.courses-info-item {
			width: 100%;
			margin-right: 0;
		}
	}
}
@media (max-width: $bp-md - 1) {
	.courses-info-wrapper {
		flex-wrap: wrap;
		.courses-info-item {
			width: calc(50% - 10px);
			margin-right: 10px;
			margin-bottom: 10px;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}
@media (max-width: $bp-sm - 1) {
	.course-detail-wrapper {
		display: flex;
		flex-wrap: wrap;
		.image {
			width: 100%; 
			height: 300px;
			margin-right: 0;
			margin-bottom: 40px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.desc {
			width: 100%;
		}
	}
}

@media (max-width: $bp-xs - 1) {
	.courses-info-wrapper {
		flex-wrap: wrap;
		.courses-info-item {
			margin: auto;
			width: 80%;

			margin-bottom: 10px;
			&:nth-child(2n) {
				margin-right: 0;
				margin: auto;
				margin-bottom: 10px;
			}
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.courses-info-wrapper {
		flex-wrap: wrap;
		.courses-info-item {
			margin: auto;
			width: 100%;
			padding: 30px 10px;
			margin-bottom: 10px;
			&:nth-child(2n) {
				margin-right: 0;
				margin: auto;
				margin-bottom: 10px;
			}
		}
	}
	.courses-wrapper {
		.courses-info-item {
			width: 100%;
			margin-right: 0;
			flex-wrap: wrap;
			img {
				width: 100%;
				border-radius: 10px 10px 0 0;
				object-fit: cover;
			}
			.course-desc {
				width: 100%;
			}
		}
	}
	.course-detail-wrapper {
		.desc {
			.course-content-price {
				flex-wrap: wrap;
				.course-content {
					width: 100%;
					margin-right: 0;
					margin-bottom: 40px;
				}
				.course-price {
					width: 100%;
				}
			}
		}
	}
}
