.forom-wrapper {
	position: relative;
	z-index: 6;
	display: flex;
	width: calc(1000px);
	max-width: 100%;
	margin: auto;
	background: #fff;
	border-radius: 25px;
	overflow: hidden;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .16);
	margin: 60px auto;
	.form-description,
	.form-form {
		padding: 30px;
		flex-grow: 1;
	}
	.form-form {
		width: 600px;
		form {
			display: flex;
			flex-wrap: wrap;
			.form-group {
				margin-bottom: 18px;
				flex-grow: 1;
				padding-right: 10px;
				width: 250px;
				&.group-full {
					width: 100%;
				}
				label {
					display: block;
					margin-bottom: 6px;
					width: 100%;
					font-weight: 700;
				}
				input[type="text"],
				input[type="email"] {
					padding: 12px 10px;
					border-radius: 4px;
					outline: none;
					border: 1px solid $base-color;
					width: 100%;
					transition: all 250ms ease-in-out;
					&:focus {
						box-shadow: inset 0 -3px 0 0 $base-color;
					}
				}
				textarea {
					width: 100%;
					padding: 8px 10px;
					border-radius: 3px;
					outline: none;
					border: 1px solid $base-color;
					transition: all 250ms ease-in-out;
					&:focus {
						box-shadow: inset 0 -3px 0 0 $base-color;
					}
				}

				.checkboxes-wrapper {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
				}

				label.chechbox-label {
					display: flex;
					align-items: center;
					width: auto !important;
					padding: 14px 15px;
					border: 1px solid #e2e2e2;
					background-color: #fff;
					min-width: 260px;
					border-radius: 8px;
					margin: 10px 18px 10px 0;
					cursor: pointer;
					transition: all 250ms ease-in-out;
					input[type="radio"],
					input[type="checkbox"] {
						display: none;
					}

					&:before {
						content: "";
						width: 20px;
						height: 20px;
						display: block;
						color: #fff;
						margin-right: 20px;
						background-color: #dddddd;
						border-radius: 5px;
						transition: all 250ms ease-in-out;
					}
					&.checked {
						border-color: #91b508;
						box-shadow: inset 0 0 0 1px #91b508, 0 7px 10px 0 rgba(0, 0, 0, .16);
						&:before {
							content: "✓";
							line-height: 20px;
							text-align: center;
							background-color: #91b508;
						}
					}
					&.out-of-stock {
						cursor: not-allowed;
						span.small {
							color: red;
						}
					}
				}
				.label-price {
					white-space: nowrap;
					font-size: 25px;
					text-align: right;
					.detail-product-sale-original {
						position: absolute;
						bottom: 100%;
						right: 0;
						font-size: 1.1rem;
						display: inline-block;
						background-color: red;
						border-radius: 8px;
						color: #fff;
						padding: 3px 6px;
						position: absolute;
						text-decoration: line-through;
						font-weight: 400;
					}
				}
				.label-volume {
					color: #2d2a24;
					flex-grow: 1;
					font-weight: 400;
					line-height: 1;
					span.small {
						display: block;
						width: 100%;
						margin-top: 3px;
						color: #91b508;
					}
				}

				.group-price {
					& > div {
						font-size: 3rem;
						font-weight: 700;
					}
				}
			}
			#increment_input {
				display: flex;
				justify-content: center;
				width: 150px;
				padding: 14px 15px;
				border: 1px solid #e2e2e2;
				border-radius: 8px;
				input {
					border: none;
					outline: none;
					width: 40px;
					//pointer-events: none;
					text-align: center;
					padding: 0;
				}
				span.inc_button {
					content: "-";
					display: block;
					width: 1.3em;
					height: 1.3em;
					color: #2d2a24;
					background-color: #ddd;
					border-radius: 50%;
					cursor: pointer;
					text-align: center;
					line-height: 1.3;
					font-size: 2rem;
					font-weight: 700;
				}
			}
		}
	}
	.form-description {
		width: 400px;
		background-color: $base-color;
		//background-image: url(../img/form-img.jpg);
		background-position: right center;
		background-size: cover;
		p,
		div,
		a,
		ul,
		li,
		h3 {
			color: #000;
		}
		h3 {
			font-weight: 700;
		}
		.form-contact {
			font-size: 1.8rem;
			font-weight: 700;
			margin-bottom: 20px;
			img {
				margin-right: 10px;
			}
		}
	}
}
.forom-wrapper .form-form form.order-form .form-group {
	width: 50%;
	&.group-full {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		label,
		strong {
			width: 100%;
		}
		&.w-price {
			align-items: flex-start;
		}
	}
}

#genericForm_forms_flash,
#productForm_forms_flash {
	width: 100%;
	.alert {
		color: #155724;
		background-color: #d4edda;
		padding: 5px;
		display: table;
		margin: auto;

		text-align: center;
		width: 100%;

		p {
			margin: 0;
		}
		li {
			margin: 5px auto;
		}
		ul {
			list-style-type: disc;
			display: flex;
			flex-direction: column;
			margin: 5px;
		}
		&.alert-danger {
			color: #721c24;
			background-color: #f8d7da;
		}
		.close {
			display: none;
			margin: 0 0 0 auto;
			border: none;
			span {
				font-size: 16px;
				color: #721c24;
			}
		}
	}
}

@media (max-width: $bp-sm - 1) {
	.forom-wrapper {
		flex-wrap: wrap;
		.form-description,
		.form-form {
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

@media (max-width: $bp-xs - 1) {
	.forom-wrapper {
		max-width: calc(100% + 30px);
		margin: 20px -15px;
	}
}
