@import '../variables';
.subanner-wrapper {
	position: relative;
	padding: 140px 0 250px;
	h1 {
		position: relative;
		font-size: 4.6rem;
		z-index: 2;
		text-align: center;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		//white-space: nowrap;
		line-height: 1.1;
		margin-bottom: 0;
		&::before {
			display: none;
		}
	}

	&::before {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url("../img/subanner.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}
@media (max-width: $bp-xs - 1) {
	.subanner-wrapper {
		padding: 120px 0 50px;
		h1 {
			font-size: 3rem;
		}
		&:before {
			background-image: url("../img/subbanner-mobile.jpg");
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba(0, 0, 0, .4);
		}
	}
}
