@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Global styles TOC
	Reseter
	Fonts
	Typo
	Global classes
	Bootstrap alter
*/
/* Colors */
/* Menu */
/* Typo */
button,
input[type=submit] {
  cursor: pointer;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  transition: 200ms ease-in-out;
  color: #f9c206;
}

li {
  font-size: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

img.no-resize {
  max-width: none;
}

body .fs-40 {
  font-size: 40px;
}
body .fs-36 {
  font-size: 36px;
}
body .fs-34 {
  font-size: 34px;
}
body .fs-30 {
  font-size: 30px;
}
body .fs-24 {
  font-size: 24px;
}
body .fs-20 {
  font-size: 20px;
}
body .fs-18 {
  font-size: 18px;
}
body .fs-16 {
  font-size: 16px;
}
body .fs-15 {
  font-size: 15px;
}
body .fs-14 {
  font-size: 14px;
}
body .fw-300 {
  font-weight: 300;
}
body .fw-400 {
  font-weight: 400;
}
body .fw-500 {
  font-weight: 500;
}
body .fw-700 {
  font-weight: 700;
}
body .text-center {
  text-align: center;
}
body .text-left {
  text-align: left;
}
body .text-right {
  text-align: right;
}
body .td-underline {
  text-decoration: underline;
}
body .text-green {
  color: #f9c206;
}
body .monserat {
  font-family: "Montserrat", sans-serif;
}

.no-border {
  border: none !important;
}

.small {
  font-size: 0.8em;
}

.big {
  font-size: 1.6em;
}

.nowrap {
  white-space: nowrap;
}

.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .turncate-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.w-100 {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.td-under {
  text-decoration: underline;
}

.mb-1 {
  margin-bottom: 25px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* Global classes */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.ac-center {
  align-content: center;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.row .col {
  flex-grow: 1;
}
.row .col-ng {
  flex-grow: 0;
}

.ma {
  margin: auto;
}

.no-margin {
  margin: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Own bootstrap alternative */
.container-small,
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 100%;
}

.col-1 {
  padding: 0 10px;
  width: calc((1 / 12) * 100%);
}

.col-2 {
  padding: 0 10px;
  width: calc((2 / 12) * 100%);
}

.col-3 {
  padding: 0 10px;
  width: calc((3 / 12) * 100%);
}

.col-4 {
  padding: 0 10px;
  width: calc((4 / 12) * 100%);
}

.col-5 {
  padding: 0 10px;
  width: calc((5 / 12) * 100%);
}

.col-6 {
  padding: 0 10px;
  width: calc((6 / 12) * 100%);
}

.col-7 {
  padding: 0 10px;
  width: calc((7 / 12) * 100%);
}

.col-8 {
  padding: 0 10px;
  width: calc((8 / 12) * 100%);
}

.col-9 {
  padding: 0 10px;
  width: calc((9 / 12) * 100%);
}

.col-10 {
  padding: 0 10px;
  width: calc((10 / 12) * 100%);
}

.col-11 {
  padding: 0 10px;
  width: calc((11 / 12) * 100%);
}

.col-12 {
  padding: 0 10px;
  width: calc((12 / 12) * 100%);
}

@media (min-width: 768px) {
  .container-small,
  .container {
    width: 750px;
  }
  .col-xs-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-xs-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-xs-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-xs-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-xs-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-xs-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-xs-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-xs-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-xs-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-xs-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-xs-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-xs-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 992px) {
  .container-small,
  .container {
    width: 970px;
  }
  .col-sm-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-sm-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-sm-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-sm-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-sm-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-sm-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-sm-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-sm-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-sm-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-sm-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-sm-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-sm-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 1200px) {
  .container-small,
  .container {
    width: 1170px;
  }
  .col-md-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-md-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-md-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-md-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-md-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-md-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-md-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-md-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-md-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-md-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-md-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-md-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 1450px) {
  .container-small,
  .container {
    width: 1400px;
  }
  .col-lg-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-lg-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-lg-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-lg-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-lg-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-lg-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-lg-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-lg-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-lg-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-lg-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-lg-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-lg-12 {
    width: calc((12 / 12) * 100%);
  }
}
table {
  margin-bottom: 20px;
  font-size: 16px;
}
table td {
  padding: 10px 25px;
}

.table-stripped tr:nth-child(odd) {
  background: #f2f2f2;
}

.table-full {
  width: 100%;
}

@media (max-width: 555px) {
  .hidden-xxs-down {
    display: none;
  }
  table {
    font-size: 14px;
  }
  table td {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .hidden-xs-down {
    display: none;
  }
}
@media (max-width: 991px) {
  .hidden-sm-down {
    display: none;
  }
}
@media (max-width: 1199px) {
  .hidden-md-down {
    display: none;
  }
}
@media (max-width: 1449px) {
  .hidden-lg-down {
    display: none;
  }
}
.d-none {
  display: none;
  visibility: hidden;
  opacity: 0;
  z-index: -2000;
}

.visible-only-xxs,
.visible-only-xs,
.visible-only-sm,
.visible-only-md,
.visible-only-lg {
  display: none;
}

@media (max-width: 555px) {
  .visible-only-xxs {
    display: inline-block;
  }
}
@media screen and (min-width: 556px) and (max-width: 767px) {
  .visible-only-xs {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-only-xs {
    display: inline-block;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-only-sm {
    display: inline-block;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1449px) {
  .visible-only-md {
    display: inline-block;
  }
}
.btn-mobile {
  display: none;
}

@media (max-width: 767px) {
  .btn-desktop {
    display: none;
  }
  .btn-mobile {
    display: table;
  }
}
@media (max-width: 1749px) {
  .flex-wrap-xl {
    flex-wrap: wrap;
  }
}
@media (max-width: 1449px) {
  .flex-wrap-lg {
    flex-wrap: wrap;
  }
}
@media (max-width: 1199px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
@media (max-width: 555px) {
  .flex-wrap-xxs {
    flex-wrap: wrap;
  }
}
@media (max-width: 354px) {
  .flex-wrap-xxxs {
    flex-wrap: wrap;
  }
}
/* Colors */
/* Menu */
html {
  font-size: 10px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #383e42;
}

textarea,
button,
input {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

h1, .h1 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 30px;
  line-height: 1;
  text-align: center;
  color: #5a4524;
}
h1::before, .h1::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
  height: 3px;
  background-color: #f9c206;
  border-radius: 30px;
}

h2, .h2 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 4.4rem;
  margin-bottom: 0.5em;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  color: #5a4524;
  padding-bottom: 30px;
}
h2::before, .h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}
h2.text-left, .h2.text-left {
  text-align: left;
}
h2.text-left::before, .h2.text-left::before {
  left: 0;
  transform: unset;
}

h3, .h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 2.8rem;
  position: relative;
  font-weight: 700;
  margin-bottom: 0.6em;
  line-height: 1.1;
  color: #5a4524;
}

p {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #5a4524;
}

div,
ul,
ol,
table {
  font-size: 1.6rem;
  color: #5a4524;
}

main ul {
  list-style-type: disc;
  padding-left: 20px;
  line-height: 1.6;
}
main ul li {
  margin-bottom: 10px;
  line-height: 1.6;
}
main div {
  line-height: 1.6;
}

a,
li,
td,
span,
input,
textarea,
label,
th {
  font-size: inherit;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 3.8rem;
  }
  h2 {
    font-size: 2.6rem;
    margin-bottom: 1.5em;
    padding-bottom: 0.5em;
  }
  h3 {
    font-size: 2.2rem;
    margin-bottom: 0.5em;
  }
}
.text-red {
  color: #f13232 !important;
}

/* Colors */
/* Menu */
.ico:before {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.ico.fa-2x:before {
  width: 2em;
  height: 2em;
}
.ico.fa-3x:before {
  width: 3em;
  height: 3em;
}
.ico.icon-5x:before {
  width: 5em;
  height: 5em;
}
.ico.icon-marker::before {
  background-image: url("../img/marker.svg");
}

/* Fonts */
/*
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-light.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-light.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-light.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-light.svg') format('svg'); 
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-regular.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-regular.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-regular.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-regular.svg') format('svg'); 
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.svg') format('svg'); 
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-medium.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-medium.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-medium.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-medium.svg') format('svg'); 
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-semibold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-semibold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-semibold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-semibold.svg') format('svg'); 
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-bold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-bold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-bold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-bold.svg') format('svg'); 
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Light.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Regular.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Bold.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
*/
/* Colors */
/* Menu */
a.fill-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.btn-std {
  background-color: #f9c206;
  color: #5a4524;
  font-weight: 700;
  display: table;
  line-height: 1;
  margin: 10px auto;
  padding: 16px 26px;
  text-align: center;
  max-width: 100%;
  border: none;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important;
}
.btn-std.btn-reve {
  background: transparent;
}
.btn-std.btn-left {
  margin: 10px auto 10px 0;
}
.btn-std.btn-right {
  margin: 10px 0 10px auto;
}
.btn-std.btn-center {
  margin: 10px auto;
}
.btn-std:hover {
  background-color: #5a4524;
  box-shadow: inset 0 0 0 2px #f9c206;
  color: #f9c206;
}

a.btn-arrow {
  padding-right: 25px;
  display: table;
  margin: 16px 0 0 auto;
  position: relative;
  font-weight: 700;
}
a.btn-arrow::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/arrow-right.svg");
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms ease-in-out;
}
a.btn-arrow:hover::before {
  right: -5px;
}

/* Colors */
/* Menu */
section.global-section {
  padding: 60px 0;
}

section.color-section {
  padding: 60px 0;
  background-color: #f8f3f0;
  overflow: hidden;
}
section.color-section strong {
  color: #5a4524;
  font-weight: 700;
}
section.color-section ul li {
  color: #5a4524;
}

.bee-section {
  position: relative;
}
.bee-section h2 {
  position: relative;
  z-index: 10;
}
.bee-section::before {
  position: absolute;
  content: "";
  background-image: url("../img/bee-hp.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  right: -100px;
  top: 0;
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.honey-section {
  position: relative;
}
.honey-section h2 {
  position: relative;
  z-index: 10;
}
.honey-section::after {
  position: absolute;
  content: "";
  background-image: url("../img/honey-hp.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: -150px;
  top: 0;
  width: 300px;
  height: 300px;
  overflow: hidden;
}

section.bee-honey-section {
  position: relative;
  padding-top: 0;
}
section.bee-honey-section h2 {
  position: relative;
  z-index: 10;
}
section.bee-honey-section::before {
  position: absolute;
  content: "";
  background-image: url("../img/bee-hp.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  right: 0;
  top: 0;
  width: 300px;
  height: 300px;
}
section.bee-honey-section::after {
  position: absolute;
  content: "";
  background-image: url("../img/honey-hp.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: -150px;
  top: 0;
  width: 300px;
  height: 300px;
  overflow: hidden;
}
section.bee-honey-section.honey-bee-hp::before {
  right: -150px;
  overflow: hidden;
  top: 60%;
}
section.bee-honey-section.honey-bee-hp::after {
  background-image: url("../img/honey-hp-news.png");
  top: 25%;
  left: -200px;
  width: 400px;
  height: 400px;
}

@media (max-width: 767px) {
  section.bee-honey-section {
    padding-top: 50px;
  }
}
.p-middle {
  width: 700px;
  max-width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 60px;
}
.p-middle span {
  display: inline-block;
}

@media (max-width: 1199px) {
  .bee-honey-section::before {
    width: 180px;
  }
  .bee-honey-section::after {
    width: 220px;
  }
}
@media (max-width: 767px) {
  .bee-section::before {
    width: 180px;
    display: none;
  }
  .bee-honey-section::before {
    width: 180px;
    display: none;
  }
  .bee-honey-section::after {
    width: 220px;
    display: none;
  }
  .honey-section::after {
    width: 220px;
    display: none;
  }
}
/* Colors */
/* Menu */
body {
  padding-top: 0;
}

#mainMenu.toFixed {
  position: fixed;
  background-color: #5a4524;
  animation: slide-down 500ms;
  box-shadow: 0 11px 38px 0 rgba(0, 0, 0, 0.4);
  transition: all 250ms ease-in-out;
}
#mainMenu.toFixed #header-bottom-line {
  position: relative;
  padding-bottom: 10px;
}
#mainMenu.toFixed #header-bottom-line::before {
  display: none;
}
#mainMenu.toFixed nav > ul {
  color: #f9c206;
}
#mainMenu.toFixed .frequent_links .icon-header:after {
  filter: none;
}
#mainMenu.toFixed .logo img {
  filter: none;
}
#mainMenu.toFixed .nav-inner {
  padding: 10px 0;
}

header#mainMenu {
  position: absolute;
  display: flex;
  background: transparent;
  background-color: transparent;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
  height: 96px;
  z-index: 999;
}
header#mainMenu > .container {
  margin: auto;
}

#header-bottom-line {
  position: relative;
  padding-bottom: 10px;
}
#header-bottom-line::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.header-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-wrapper label.hamburger {
  z-index: 9999;
  cursor: pointer;
  width: 40px;
  height: 50px;
  margin-right: 0;
  margin-left: auto;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.header-wrapper label.hamburger input {
  display: none;
}
.header-wrapper label.hamburger span {
  display: inline-block;
  width: 40px;
  height: 4px;
  border-radius: 3px;
  background: #fff;
  margin: 4px 0 4px auto;
  transition: all 200ms ease-in-out;
}
.header-wrapper label.hamburger span:nth-child(3) {
  width: 30px;
}
.header-wrapper label.hamburger:hover span:nth-child(3) {
  width: 40px;
}
.header-wrapper .logo {
  margin: 0 auto 0 0;
}
.header-wrapper nav ul.navigation {
  display: flex;
  align-items: center;
}
.header-wrapper nav ul.navigation li {
  position: relative;
  margin-left: 30px;
}
@media screen and (max-width: 1449px) {
  .header-wrapper nav ul.navigation li {
    margin-left: 20px;
  }
}
.header-wrapper nav ul.navigation li a {
  display: inline-block;
  position: relative;
  padding: 30px 0px;
  color: #fff;
  font-weight: 700;
}
.header-wrapper nav ul.navigation li a:before {
  content: "";
  position: absolute;
  display: inline-block;
  top: 100%;
  left: 50%;
  height: 4px;
  width: 0px;
  transform: translate(-50%, -20px);
  background-color: #f9c206;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
}
.header-wrapper nav ul.navigation li a:hover {
  color: #f9c206;
}
.header-wrapper nav ul.navigation li a:hover:before {
  width: 50px;
}
.header-wrapper nav ul.navigation li a.active {
  color: #f9c206;
}
.header-wrapper nav ul.navigation li a.active:before {
  width: 50px;
}
.header-wrapper nav ul.navigation li a.--api {
  text-transform: capitalize;
}
.header-wrapper nav ul.navigation li a.header-main-btn {
  position: relative;
  color: #fff !important;
  background: #f9c206;
  border-radius: 9px;
  padding: 14px 30px;
  margin-left: 20px;
}
.header-wrapper nav ul.navigation li a.header-main-btn:hover {
  text-shadow: 0 0 10px black;
}
.header-wrapper nav ul.navigation li a.header-main-btn span {
  position: relative;
  z-index: 5;
}
.header-wrapper .logo img {
  height: 70px;
  width: auto;
  transition: all 350ms ease-in-out;
}
@media (max-width: 1449px) {
  .header-wrapper .logo img {
    height: 50px;
  }
}

@media (min-width: 991px) {
  .header-wrapper .hamburger {
    display: none;
  }
  ul.navigation {
    display: flex;
  }
  ul.navigation li .submenu {
    position: absolute;
    top: 100%;
    background: transparent;
    height: auto;
    left: 0;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.11);
    z-index: 5;
    transition: all 400ms ease-in-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  ul.navigation li .submenu li a {
    padding: 20px;
    white-space: nowrap;
  }
  ul.navigation li:last-child .submenu {
    right: 0;
    left: auto;
  }
  ul.navigation li:hover > .submenu {
    opacity: 1;
    max-height: 500px;
  }
}
@media (max-width: 990px) {
  header#mainMenu label.hamburger {
    display: flex;
    order: 10;
    margin: 0 0 0 20px;
  }
  header#mainMenu nav {
    position: fixed;
    left: 0;
    top: 0;
    background: transparent;
    z-index: 999;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 96px 10px 40px 10px;
    overflow: auto;
    opacity: 0;
    display: none;
    transition: all 300ms ease-in-out;
  }
  header#mainMenu nav ul.navigation {
    flex-direction: column;
    height: auto;
  }
  header#mainMenu nav ul.navigation li {
    margin-left: 0;
  }
  header#mainMenu nav ul.navigation li ul.submenu {
    max-height: 0;
    height: auto;
    padding-left: 20px;
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }
  header#mainMenu nav ul.navigation li a {
    width: 100%;
    padding: 12px 20px;
  }
  header#mainMenu nav ul.navigation li a::before {
    top: 125%;
  }
  header#mainMenu nav ul.navigation li:focus ul.submenu, header#mainMenu nav ul.navigation li:hover ul.submenu {
    max-height: 500px;
  }
  body.menuOpen {
    overflow: hidden;
  }
  body.menuOpen header#mainMenu nav {
    opacity: 1;
    display: block;
  }
}
@media (max-width: 991px) {
  body.menuOpen .header-wrapper .logo img {
    filter: none;
  }
  body.menuOpen .header-wrapper .logo {
    position: relative;
    z-index: 1000;
  }
  body.menuOpen header#mainMenu nav {
    background-color: #fff;
    padding-top: 100px;
  }
  body.menuOpen header#mainMenu nav li a {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    padding: 12px 20px;
    width: 100%;
    margin: 0;
  }
  body.menuOpen .hamburger span {
    background-color: #333;
  }
  body.menuOpen .hamburger span:nth-child(3) {
    width: 0;
    opacity: 0;
  }
  body.menuOpen .hamburger span:nth-child(2) {
    transform: rotate(45deg) translate(10px, 6px);
  }
  body.menuOpen .hamburger span:nth-child(4) {
    transform: rotate(-45deg) translate(11px, -8px);
  }
}
/* Colors */
/* Menu */
.first-section {
  background-image: url("../img/footer-bg.png");
  padding-top: 250px;
  background-color: #f8f3f0;
}
.first-section .footer-wrapper .logo-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.first-section .footer-wrapper .logo-contact .logo img {
  height: 80px;
  width: auto;
}
.first-section .footer-wrapper .logo-contact .contact {
  display: flex;
}
.first-section .footer-wrapper .logo-contact .contact a {
  display: block;
  margin-right: 20px;
}
.first-section .footer-wrapper .logo-contact .contact a:last-child {
  margin-right: 0;
}
.first-section .footer-wrapper .logo-contact .contact div {
  position: relative;
  padding-left: 30px;
  color: #fff;
  margin-right: 20px;
}
.first-section .footer-wrapper .logo-contact .contact div::before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.first-section .footer-wrapper .logo-contact .contact div.address::before {
  background-image: url("../img/marker.svg");
}
.first-section .footer-wrapper .logo-contact .contact div.phone::before {
  background-image: url("../img/phone.svg");
}
.first-section .footer-wrapper .logo-contact .contact div.mail::before {
  background-image: url("../img/mail.svg");
}
.first-section .footer-wrapper .menu-socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}
.first-section .footer-wrapper .menu-socials .menu {
  display: flex;
}
.first-section .footer-wrapper .menu-socials .menu a {
  display: block;
  margin-right: 30px;
  color: #fff;
  font-weight: 700;
}
.first-section .footer-wrapper .menu-socials .menu a:hover {
  color: #f9c206;
}
.first-section .footer-wrapper .menu-socials .socials img {
  width: 55px;
  height: auto;
  margin-right: 10px;
}

.second-section {
  background-color: #4d3b1f;
  position: relative;
  padding: 20px;
}
.second-section::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.14);
}
.second-section div {
  text-align: center;
  color: #fff;
}

@media (max-width: 991px) {
  .first-section {
    background-image: url("../img/footer-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 250px;
    background-color: #f8f3f0;
  }
  .first-section .footer-wrapper .logo-contact {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .first-section .footer-wrapper .logo-contact .logo {
    width: 100%;
    margin-bottom: 40px;
  }
  .first-section .footer-wrapper .logo-contact .logo img {
    margin: auto;
    display: block;
  }
  .first-section .footer-wrapper .logo-contact .contact {
    display: block;
    margin: auto;
  }
  .first-section .footer-wrapper .logo-contact .contact a {
    margin: auto;
    display: table;
  }
  .first-section .footer-wrapper .logo-contact .contact div {
    margin: auto;
    margin-bottom: 40px;
  }
  .first-section .footer-wrapper .menu-socials {
    flex-wrap: wrap;
  }
  .first-section .footer-wrapper .menu-socials .menu {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .first-section .footer-wrapper .menu-socials .menu a {
    margin: auto;
    display: table;
    margin-bottom: 15px;
  }
  .first-section .footer-wrapper .menu-socials .socials {
    margin: auto;
  }
}
/* Colors */
/* Menu */
header#mainMenu.scrolled {
  position: fixed;
  background-color: #5a4524;
  box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
}
header#mainMenu.scrolled .logo img {
  filter: none;
}
header#mainMenu.scrolled .hamburger span {
  background-color: #333;
}
header#mainMenu.scrolled ul.navigation li a {
  color: #fff;
}
header#mainMenu.scrolled ul.navigation li a:hover {
  color: #f9c206;
}

/* Colors */
/* Menu */
/* Colors */
/* Menu */
.banner-wrapper {
  position: relative;
  background-image: url("../img/webp/banner.webp"), url("../img/banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.banner-wrapper .banner-desc {
  padding-top: 160px;
  padding-bottom: 180px;
  position: relative;
  z-index: 4;
  width: 55%;
}
.banner-wrapper .banner-desc h1 {
  text-align: left;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1.1;
  padding-bottom: 0;
  margin-bottom: 15px;
}
.banner-wrapper .banner-desc h1::before {
  display: none;
}
.banner-wrapper .banner-desc p {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 40px;
}
.banner-wrapper .banner-desc .btn-std {
  padding: 20px 40px;
}
.banner-wrapper::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  bottom: -4px;
  left: 0%;
  background-image: url("../img/banner-wave.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  z-index: 2;
}
.banner-wrapper::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.17) 100%);
  opacity: 0.35;
}

@media (max-width: 1199px) {
  /*.banner-wrapper {
  	&::before {
  		position: absolute;
  		content: "";
  		width: 105%;
  		height: 35%;
  		bottom: 0;
  		left: -2%;
  		background-image: url("../img/banner-hp-before.png");
  		background-size: cover;
  		background-position: top;
  		background-repeat: no-repeat;
  		z-index: 2;
  	}
  }*/
}
@media (max-width: 991px) {
  .banner-wrapper .banner-desc {
    width: 100%;
    padding-top: 170px;
  }
  .banner-wrapper .banner-desc h1 {
    white-space: unset;
    font-size: 4rem;
  }
  .banner-wrapper .banner-desc p {
    font-size: 1.8rem;
  }
}
@media (max-width: 555px) {
  .banner-wrapper {
    background-position: right center;
  }
  .banner-wrapper p {
    display: none;
  }
  .banner-wrapper .banner-desc {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .banner-wrapper .banner-desc h1 {
    white-space: unset;
    font-size: 2.4rem;
    line-height: 1.4;
  }
  .banner-wrapper .banner-desc p {
    font-size: 1.6rem;
  }
  .banner-wrapper .banner-desc .btn-std {
    padding: 20px 20px;
  }
}
/* Colors */
/* Menu */
.product-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.product-wrapper .product-item {
  width: calc(50% - 25px);
  margin-right: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  transition: all 250ms ease-in-out;
}
.product-wrapper .product-item.--api {
  width: 100%;
}
.product-wrapper .product-item.--api h3 {
  font-size: 3.8rem;
}
@media (max-width: 767px) {
  .product-wrapper .product-item.--api h3 {
    font-size: 2.8rem;
  }
}
.product-wrapper .product-item.--api p {
  font-size: 2.2rem;
}
@media (max-width: 767px) {
  .product-wrapper .product-item.--api p {
    font-size: 1.6rem;
  }
}
.product-wrapper .product-item.--api .product-image {
  width: 320px;
  flex-shrink: 0;
}
.product-wrapper .product-item.--api .product-image img {
  object-fit: cover;
  border-radius: 10px;
}
.product-wrapper .product-item:hover {
  transform: translate(0, -10px);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
}
.product-wrapper .product-item .product-image {
  display: inline-block;
  width: 140px;
  height: 150px;
  position: relative;
  max-width: 100%;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .product-wrapper .product-item .product-image {
    margin-right: 0;
  }
}
.product-wrapper .product-item .product-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}
.product-wrapper .product-item .product-desc {
  width: calc(100% - 140px);
}
.product-wrapper .product-item .product-desc h3 {
  color: #5a4524;
  font-weight: 700;
}
.product-wrapper .product-item .product-desc a {
  padding-right: 50px;
  display: table;
  margin: 0 0 0 auto;
  position: relative;
  font-weight: 700;
}
.product-wrapper .product-item .product-desc a::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/arrow-right.svg");
  right: 25px;
  top: 50%;
  transform: translate(0, -50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms ease-in-out;
}
.product-wrapper .product-item .product-desc a:hover::before {
  right: 20px;
}

.product-news-wrapper {
  width: 55%;
  margin: 60px auto 0;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  padding: 30px 60px 30px 110px;
  border: 3px solid #f9c206;
}
.product-news-wrapper::before {
  content: "Akce";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  right: -60px;
  top: -60px;
  width: 120px;
  height: 120px;
  background-image: url(../img/akce.svg);
  background-position: 50% 7px;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-news-wrapper h2 {
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 0;
}
.product-news-wrapper h2::before {
  display: none;
}
.product-news-wrapper img {
  width: 200px;
  height: auto;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product-news-wrapper .action-price {
  position: relative;
  margin-top: 8px;
}
.product-news-wrapper .action-price .sale-count {
  position: absolute;
  bottom: calc(100% - 4px);
  left: 0;
  font-size: 1.1rem;
  display: inline-block;
  background-color: #ff6969;
  border-radius: 8px;
  color: #fff;
  padding: 3px 6px;
  position: absolute;
  font-weight: 400;
}
.product-news-wrapper .action-price .new-price {
  font-weight: 700;
  font-size: 3rem;
  margin-right: 10px;
  display: inline-block;
}
.product-news-wrapper .action-price .original-price {
  color: rgba(0, 0, 0, 0.36);
  text-decoration: line-through;
}

.product-detail-wrapper {
  display: flex;
}
.product-detail-wrapper .image {
  width: 40%;
  margin-right: 50px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  height: 380px;
}
.product-detail-wrapper .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-detail-wrapper .desc {
  width: 60%;
}
.product-detail-wrapper .desc.api-desc {
  width: 100%;
}
.product-detail-wrapper .desc.api-desc strong {
  display: inline-block;
  margin-bottom: 0;
}
.product-detail-wrapper .desc h2 {
  text-align: left;
}
.product-detail-wrapper .desc h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100px;
  height: 3px;
  background-color: #f9c206;
  border-radius: 30px;
}
.product-detail-wrapper .desc div li {
  color: #5a4524;
}
.product-detail-wrapper .desc div p {
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .product-detail-wrapper {
    display: flex;
  }
  .product-detail-wrapper .image {
    width: 45%;
  }
  .product-detail-wrapper .desc {
    width: 55%;
  }
}
@media (max-width: 1199px) {
  .product-detail-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .product-detail-wrapper .image {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
  .product-detail-wrapper .desc {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .product-wrapper .product-item {
    width: 100%;
    margin-right: 0;
  }
  .product-news-wrapper {
    width: 80%;
    padding: 30px 10px 30px 70px;
  }
  .product-news-wrapper img {
    width: 140px;
  }
}
@media (max-width: 767px) {
  .product-wrapper .product-item {
    width: 100%;
    margin-right: 0;
    flex-direction: column;
  }
  .product-wrapper .product-item .product-image {
    margin-bottom: 20px;
    height: 150px;
  }
  .product-wrapper .product-item .product-desc {
    width: 100%;
  }
  .product-news-wrapper {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 0;
    padding: 70px 15px 30px 15px;
  }
  .product-news-wrapper img {
    left: 0;
    top: 0;
    transform: translate(0%, -70px);
  }
  .product-news-wrapper:before {
    right: 0;
  }
}
.slick-arrows-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.slick-dots {
  list-style: none !important;
}

.slick-arrow {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #f9c206;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}
.slick-arrow:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/slick-left.svg");
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}
.slick-arrow.arrow-next:before {
  background-image: url("../img/slick-right.svg");
}

ul.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
ul.slick-dots > li button {
  display: block;
  background-color: #fff;
  font-size: 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 0 5px;
  border: 2px solid #f9c206;
}
ul.slick-dots li.slick-active button {
  background-color: #f9c206;
  transform: scale(1.1);
}

.category-products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.category-products-list .category-product-item {
  width: 300px;
  margin: 10px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 10px;
  transition: all 250ms ease-in-out;
}
.category-products-list .category-product-item.not-available {
  opacity: 0.7;
}
.category-products-list .category-product-item.not-available h3 {
  text-decoration: line-through;
}
.category-products-list .category-product-item.not-available .product-price {
  color: #dc3545;
}
.category-products-list .category-product-item .product-price {
  color: #000;
  font-weight: 500;
}
.category-products-list .category-product-item .product-desc {
  text-align: center;
}
.category-products-list .category-product-item .product-image {
  display: flex;
}
.category-products-list .category-product-item .product-image img {
  height: 160px;
  width: auto;
  margin: 0 auto;
  max-width: 100%;
}
.category-products-list .category-product-item h3 {
  font-size: 2rem;
}
.category-products-list .category-product-item:hover {
  transform: translate(0, -10px);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
}

.product-tabs {
  display: flex;
  flex-wrap: wrap;
}
.product-tabs .tab {
  padding: 15px 18px;
  font-weight: 700;
  font-size: 1.8rem;
  margin-right: 6px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}
.product-tabs .tab.tab-active {
  background-color: #fff;
  box-shadow: inset 0 4px 0 0 #f9c206;
}
.product-tabs .tab:hover {
  box-shadow: inset 0 4px 0 0 #f9c206;
}

.tab-content {
  display: none;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 32px;
}
.tab-content h2 {
  font-size: 3rem;
  padding-bottom: 16px;
}
.tab-content.tab-active {
  display: block;
}

@media (max-width: 555px) {
  .product-tabs .tab {
    width: 100%;
    margin-right: 0;
  }
}
.box-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 16px 15px;
  transition: all 250ms ease-in-out;
}
.product-item:hover {
  transform: translate(0, -10px);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
}

.category-products-list-n {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 60px;
}
.category-products-list-n .product-item {
  flex-direction: column;
}
@media (min-width: 556px) {
  .category-products-list-n .product-item {
    width: calc(50% - 10px);
  }
}
@media (min-width: 768px) {
  .category-products-list-n .product-item {
    width: calc(33% - 11px);
  }
}
@media (min-width: 1200px) {
  .category-products-list-n .product-item {
    width: calc(25% - 15px);
  }
}
@media (min-width: 1450px) {
  .category-products-list-n .product-item {
    width: calc(20% - 16px);
  }
}
.category-products-list-n .product-item h3 {
  text-align: center;
  font-size: 18px;
}
.category-products-list-n .product-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.category-products-list-n .product-image {
  background-color: #f9c206;
  width: 80%;
  padding-top: 80%;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}
.category-products-list-n .product-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  object-position: center;
}

.products-category {
  padding-left: 20px;
  border-left: 3px solid #f9c206;
}

.category-product-item.api-item {
  padding: 20px;
  border-left: 4px solid #f9c206;
}
.category-product-item.api-item .btn-std {
  margin-left: 0;
}
.category-product-item.api-item * {
  text-align: left;
}

.food-for-bees .product-image {
  overflow: hidden;
}
.food-for-bees .product-image img {
  width: 100%;
}

/* Colors */
/* Menu */
.subanner-wrapper {
  position: relative;
  padding: 140px 0 250px;
}
.subanner-wrapper h1 {
  position: relative;
  font-size: 4.6rem;
  z-index: 2;
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0;
}
.subanner-wrapper h1::before {
  display: none;
}
.subanner-wrapper::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../img/subanner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .subanner-wrapper {
    padding: 120px 0 50px;
  }
  .subanner-wrapper h1 {
    font-size: 3rem;
  }
  .subanner-wrapper:before {
    background-image: url("../img/subbanner-mobile.jpg");
  }
  .subanner-wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
/* Colors */
/* Menu */
.courses-info-wrapper {
  display: flex;
}
.courses-info-wrapper .courses-info-item {
  background-color: #fff;
  padding: 30px 20px;
  width: 25%;
  margin-right: 10px;
}
.courses-info-wrapper .courses-info-item img {
  display: block;
  margin: auto;
  width: auto;
  height: 140px;
  margin-bottom: 40px;
}
.courses-info-wrapper .courses-info-item h3 {
  text-align: center;
  font-weight: 700;
}
.courses-info-wrapper .courses-info-item p {
  text-align: center;
}
.courses-info-wrapper .courses-info-item:last-child {
  margin-right: 0;
}

.courses-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.courses-wrapper .courses-info-item {
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
}
.courses-wrapper .courses-info-item img {
  width: 25%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
@media (max-width: 767px) {
  .courses-wrapper .courses-info-item img {
    height: 200px;
  }
}
.courses-wrapper .courses-info-item .course-desc {
  width: 75%;
  padding: 30px 20px;
}
.courses-wrapper .courses-info-item .course-desc h3 {
  font-weight: 700;
  margin-bottom: 8px;
}
.courses-wrapper .courses-info-item .course-desc p {
  margin-bottom: 10px;
}
.courses-wrapper .courses-info-item .course-desc a {
  padding-right: 50px;
  display: table;
  margin: 0 0 0 auto;
  position: relative;
  font-weight: 700;
}
.courses-wrapper .courses-info-item .course-desc a::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/arrow-right.svg");
  right: 25px;
  top: 50%;
  transform: translate(0, -50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms ease-in-out;
}
.courses-wrapper .courses-info-item .course-desc a:hover::before {
  right: 20px;
}
.courses-wrapper .courses-info-item:nth-child(2n) {
  margin-right: 0;
}

.course-detail-wrapper {
  display: flex;
}
.course-detail-wrapper .image {
  width: 35%;
  margin-right: 40px;
}
.course-detail-wrapper .image img {
  border-radius: 10px;
  width: 100%;
}
.course-detail-wrapper .desc {
  width: 65%;
}
.course-detail-wrapper .desc h2 {
  text-align: left;
}
.course-detail-wrapper .desc h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  transform: unset;
  width: 100px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}
.course-detail-wrapper .desc .course-content-price {
  display: flex;
  margin-bottom: 40px;
}
.course-detail-wrapper .desc .course-content-price .bold {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 20px;
}
.course-detail-wrapper .desc .course-content-price .course-content {
  width: 50%;
  margin-right: 30px;
}
.course-detail-wrapper .desc .course-price {
  display: flex;
}
.course-detail-wrapper .desc .course-price .price {
  font-size: 3rem;
}
.course-detail-wrapper .desc .course-price .bold {
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 20px;
}
.course-detail-wrapper .desc .content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.course-detail-wrapper .desc .content div {
  padding: 20px;
  border-bottom: 1px solid #5a4524;
}
.course-detail-wrapper .desc .content div:last-child {
  border-bottom: unset;
}

.courses-wrapper .courses-info-item {
  transition: all 250ms ease-in-out;
}
.courses-wrapper .courses-info-item:hover {
  transform: translate(0, -10px);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
}

@media (max-width: 1199px) {
  .courses-wrapper .courses-info-item {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 1199px) {
  .courses-info-wrapper {
    flex-wrap: wrap;
  }
  .courses-info-wrapper .courses-info-item {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .courses-info-wrapper .courses-info-item:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .course-detail-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .course-detail-wrapper .image {
    width: 100%;
    height: 300px;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .course-detail-wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .course-detail-wrapper .desc {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .courses-info-wrapper {
    flex-wrap: wrap;
  }
  .courses-info-wrapper .courses-info-item {
    margin: auto;
    width: 80%;
    margin-bottom: 10px;
  }
  .courses-info-wrapper .courses-info-item:nth-child(2n) {
    margin-right: 0;
    margin: auto;
    margin-bottom: 10px;
  }
}
@media (max-width: 555px) {
  .courses-info-wrapper {
    flex-wrap: wrap;
  }
  .courses-info-wrapper .courses-info-item {
    margin: auto;
    width: 100%;
    padding: 30px 10px;
    margin-bottom: 10px;
  }
  .courses-info-wrapper .courses-info-item:nth-child(2n) {
    margin-right: 0;
    margin: auto;
    margin-bottom: 10px;
  }
  .courses-wrapper .courses-info-item {
    width: 100%;
    margin-right: 0;
    flex-wrap: wrap;
  }
  .courses-wrapper .courses-info-item img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }
  .courses-wrapper .courses-info-item .course-desc {
    width: 100%;
  }
  .course-detail-wrapper .desc .course-content-price {
    flex-wrap: wrap;
  }
  .course-detail-wrapper .desc .course-content-price .course-content {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .course-detail-wrapper .desc .course-content-price .course-price {
    width: 100%;
  }
}
/* Colors */
/* Menu */
.our-offer-wrapper {
  display: flex;
  gap: 8px;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
}
.our-offer-wrapper::before {
  position: absolute;
  content: "";
  bottom: -56px;
  left: -56px;
  width: 140px;
  height: 160px;
  background-image: url("../img/bee-offer.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.our-offer-wrapper .our-offer-item {
  width: calc(33.333% - 8px);
  height: 600px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .our-offer-wrapper .our-offer-item {
    height: 400px;
  }
}
@media (max-width: 991px) {
  .our-offer-wrapper .our-offer-item {
    width: calc(50% - 8px);
  }
}
@media (max-width: 767px) {
  .our-offer-wrapper .our-offer-item {
    width: 100%;
  }
}
@media (max-width: 555px) {
  .our-offer-wrapper .our-offer-item {
    height: 350px;
  }
}
.our-offer-wrapper .our-offer-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  border-radius: 10px;
  transition: all 250ms ease-in-out;
}
.our-offer-wrapper .our-offer-item:hover img {
  transform: scale(1.1);
}
.our-offer-wrapper .our-offer-item::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 10px;
  z-index: 1;
}
.our-offer-wrapper .our-offer-item h3 {
  position: relative;
  color: #fff;
  font-weight: 700;
  margin-bottom: 8px;
  z-index: 2;
}
.our-offer-wrapper .our-offer-item p {
  position: relative;
  z-index: 2;
  font-size: 1.4rem;
  color: #fff;
  opacity: 0.6;
  margin-bottom: 0;
  line-height: 1.3;
}
.our-offer-wrapper .our-offer-item a.news-btn {
  position: relative;
  z-index: 2;
  padding-right: 50px;
  display: table;
  margin: 0 0 0 auto;
  position: relative;
  font-weight: 700;
}
.our-offer-wrapper .our-offer-item a.news-btn::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/arrow-right.svg");
  right: 25px;
  top: 50%;
  transform: translate(0, -50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms ease-in-out;
}
.our-offer-wrapper .our-offer-item a.news-btn:hover::before {
  right: 20px;
}

/* Colors */
/* Menu */
.about-us-hp-wrapper {
  display: flex;
}
.about-us-hp-wrapper .about-us {
  width: 55%;
  margin-right: 30px;
}
.about-us-hp-wrapper .about-us img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-us-hp-wrapper .about-us h3 {
  position: relative;
  padding-bottom: 15px;
  font-weight: 700;
  margin-bottom: 25px;
}
.about-us-hp-wrapper .about-us h3::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}
.about-us-hp-wrapper .about-us .text-strong {
  opacity: 1;
  font-weight: 700;
}
.about-us-hp-wrapper .news-hp {
  width: 45%;
}

@media (max-width: 1199px) {
  .about-us-hp-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .about-us-hp-wrapper .about-us {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
  .about-us-hp-wrapper .about-us img {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }
  .about-us-hp-wrapper .news-hp {
    width: 100%;
  }
}
/* Colors */
/* Menu */
.news-hp {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
}
.news-hp h3 {
  position: relative;
  padding-bottom: 15px;
  font-weight: 700;
  margin-bottom: 25px;
}
.news-hp h3::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}
.news-hp .news-wrapper-hp .news-item-hp {
  position: relative;
  transition: all 250ms ease-in-out;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.news-hp .news-wrapper-hp .news-item-hp:hover h4 {
  text-decoration: underline;
}
.news-hp .news-wrapper-hp .news-item-hp .date {
  color: #f9c206;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}
.news-hp .news-wrapper-hp .news-item-hp .date div {
  font-size: 1.4rem;
}
.news-hp .news-wrapper-hp .news-item-hp h4 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 15px;
}
.news-hp .news-wrapper-hp .news-item-hp p {
  margin-bottom: 0;
}

.news-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.news-wrapper .news-item {
  width: calc(25% - 30px);
  margin-right: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  transition: all 250ms ease-in-out;
}
.news-wrapper .news-item:hover {
  transform: translate(0, -10px);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
}
.news-wrapper .news-item .news-image {
  width: 100%;
  height: 200px;
}
.news-wrapper .news-item .news-image img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-wrapper .news-item .desc {
  padding: 15px 20px;
}
.news-wrapper .news-item .desc .date {
  display: table;
  margin: 0 0 0 auto;
  background-color: #f9c206;
  padding: 8px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.news-wrapper .news-item .desc h3 {
  font-weight: 700;
  font-size: 2.3rem;
}
.news-wrapper .news-item .desc a {
  padding-right: 25px;
  display: table;
  margin: 0 0 0 auto;
  position: relative;
  font-weight: 700;
}
.news-wrapper .news-item .desc a::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/arrow-right.svg");
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms ease-in-out;
}
.news-wrapper .news-item .desc a:hover::before {
  right: -5px;
}
.news-wrapper .news-item:nth-child(4n) {
  margin-right: 0;
}

.news-detail-wrapper {
  display: flex;
}
.news-detail-wrapper .image {
  width: 35%;
  height: 300px;
  margin-right: 30px;
}
.news-detail-wrapper .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.news-detail-wrapper .desc {
  width: 65%;
}
.news-detail-wrapper .desc .date {
  display: table;
  background-color: #f9c206;
  padding: 8px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.news-detail-wrapper .desc h2 {
  text-align: left;
}
.news-detail-wrapper .desc h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  transform: unset;
  width: 100px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}

@media (max-width: 1449px) {
  .news-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .news-wrapper .news-item {
    width: calc(33.333% - 20px);
  }
  .news-wrapper .news-item:nth-child(3n) {
    margin-right: 0;
  }
  .news-wrapper .news-item:nth-child(4n) {
    margin-right: 10px;
  }
}
@media (max-width: 1199px) {
  .news-wrapper-hp {
    display: flex;
    flex-wrap: wrap;
  }
  .news-wrapper-hp .news-item-hp {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  .news-wrapper-hp .news-item-hp:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .news-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .news-wrapper .news-item {
    width: calc(50% - 10px);
  }
  .news-wrapper .news-item:nth-child(2n) {
    margin-right: 0px;
  }
  .news-wrapper .news-item:nth-child(3n) {
    margin-right: 10px;
  }
  .news-wrapper .news-item:nth-child(4n) {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .news-detail-wrapper {
    flex-wrap: wrap;
  }
  .news-detail-wrapper .image {
    width: 100%;
    height: 300px;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .news-detail-wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .news-detail-wrapper .desc {
    width: 100%;
  }
}
@media (max-width: 555px) {
  .news-hp {
    padding: 15px;
  }
  .news-hp .news-wrapper-hp {
    display: flex;
    flex-wrap: wrap;
  }
  .news-hp .news-wrapper-hp .news-item-hp {
    width: 100%;
    margin-right: 0;
  }
  .news-hp .news-wrapper-hp .news-item-hp:nth-child(2n) {
    margin-right: 0;
  }
  .news-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .news-wrapper .news-item {
    width: 100%;
    margin-right: 0;
  }
  .news-wrapper .news-item:nth-child(2n) {
    margin-right: 0px;
  }
  .news-wrapper .news-item:nth-child(3n) {
    margin-right: 0;
  }
  .news-wrapper .news-item:nth-child(4n) {
    margin-right: 0;
  }
}
/* Colors */
/* Menu */
.contact-wrapper {
  display: flex;
}
.contact-wrapper .contact-info {
  width: 35%;
  margin-right: 30px;
}
.contact-wrapper .contact-info h2 {
  text-align: left;
  font-size: 3rem;
  padding-bottom: 15px;
  margin-bottom: 35px;
}
.contact-wrapper .contact-info h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  transform: unset;
  width: 60px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}
.contact-wrapper .contact-info .icon {
  position: relative;
  padding-left: 45px;
  margin-bottom: 40px;
  font-size: 2rem;
}
.contact-wrapper .contact-info .icon::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.contact-wrapper .contact-info .icon.marker::before {
  background-image: url("../img/marker-contact.svg");
}
.contact-wrapper .contact-info .icon.phone::before {
  background-image: url("../img/phone-contact.svg");
}
.contact-wrapper .contact-info .icon.email::before {
  background-image: url("../img/mail-contact.svg");
}
.contact-wrapper .contact-info h3 {
  font-weight: 700;
}
.contact-wrapper .contact-info .ico-contact {
  opacity: 0.6;
}
.contact-wrapper .contact-map {
  position: relative;
  width: 65%;
  height: 500px;
}
.contact-wrapper .contact-map::before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}
.contact-wrapper .contact-map a.gotomap {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../img/map.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}
.contact-wrapper .contact-map a.gotomap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-in-out;
  border-radius: 15px;
}
.contact-wrapper .contact-map a.gotomap:hover::before {
  opacity: 0.6;
}
.contact-wrapper .contact-map a.gotomap:hover span {
  opacity: 1;
}
.contact-wrapper .contact-map a.gotomap span {
  position: absolute;
  font-size: 3rem;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all 250ms ease-in-out;
  transform: translate(-50%, -50%);
  color: #fff;
  border-radius: 15px;
}

@media (max-width: 991px) {
  .contact-wrapper {
    flex-wrap: wrap;
  }
  .contact-wrapper .contact-info {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .contact-wrapper .contact-map {
    width: 100%;
  }
}
@media (max-width: 555px) {
  .contact-wrapper .contact-map {
    height: 300px;
  }
}
/* Colors */
/* Menu */
.accomodation-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.accomodation-wrapper .accomodation-item {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 10px 40px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  transition: all 250ms ease-in-out;
}
.accomodation-wrapper .accomodation-item:hover {
  transform: translate(0, -10px);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
}
.accomodation-wrapper .accomodation-item .image {
  width: 35%;
  margin-right: 15px;
}
.accomodation-wrapper .accomodation-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}
.accomodation-wrapper .accomodation-item .image.--hive-image {
  width: 20%;
}
.accomodation-wrapper .accomodation-item.api-acco:before {
  content: "API";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  right: 0;
  top: -60px;
  width: 120px;
  height: 120px;
  background-image: url(../img/akce.svg);
  background-position: 50% 7px;
  background-repeat: no-repeat;
  background-size: contain;
}
.accomodation-wrapper .accomodation-item .desc {
  width: 65%;
  padding: 30px 15px;
  margin: auto;
}
.accomodation-wrapper .accomodation-item .desc h3 {
  font-weight: 700;
  font-size: 2.3rem;
}
.accomodation-wrapper .accomodation-item .desc p {
  margin-bottom: 0;
}

.accomodation-wrapper-detail {
  display: flex;
}
.accomodation-wrapper-detail .image {
  width: 40%;
  margin-right: 30px;
}
.accomodation-wrapper-detail .image img {
  object-fit: cover;
}
.accomodation-wrapper-detail .desc {
  width: 60%;
}
.accomodation-wrapper-detail .desc h2 {
  text-align: left;
}
.accomodation-wrapper-detail .desc h2::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  transform: unset;
  width: 100px;
  height: 3px;
  border-radius: 30px;
  background-color: #f9c206;
}
.accomodation-wrapper-detail .desc ul {
  display: flex;
  flex-wrap: wrap;
}
.accomodation-wrapper-detail .desc ul li {
  width: 50%;
  opacity: 0.6;
  color: #5a4524;
  line-height: 1.4;
  position: relative;
  margin-bottom: 8px;
}

@media (max-width: 1199px) {
  .accomodation-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .accomodation-wrapper .accomodation-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .accomodation-wrapper .accomodation-item .desc div {
    margin-bottom: 15px;
  }
  .accomodation-wrapper .accomodation-item .desc a {
    margin: unset;
  }
}
@media (max-width: 991px) {
  .accomodation-wrapper-detail {
    display: flex;
    flex-wrap: wrap;
  }
  .accomodation-wrapper-detail .image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .accomodation-wrapper-detail .image img {
    object-fit: cover;
  }
  .accomodation-wrapper-detail .desc {
    width: 100%;
  }
  .accomodation-wrapper-detail .desc h2 {
    text-align: left;
  }
  .accomodation-wrapper-detail .desc h2::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    transform: unset;
    width: 100px;
    height: 3px;
    border-radius: 30px;
    background-color: #f9c206;
  }
  .accomodation-wrapper-detail .desc ul {
    display: flex;
    flex-wrap: wrap;
  }
  .accomodation-wrapper-detail .desc ul li {
    width: 50%;
    opacity: 0.6;
    color: #5a4524;
    line-height: 1.4;
    padding-left: 15px;
    position: relative;
  }
  .accomodation-wrapper-detail .desc ul li::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #f9c206;
    border-radius: 50%;
    transform: translate(0, -50%);
  }
}
@media (max-width: 767px) {
  .accomodation-wrapper-detail .desc ul {
    display: flex;
    flex-wrap: wrap;
  }
  .accomodation-wrapper-detail .desc ul li {
    width: 100%;
  }
}
@media (max-width: 555px) {
  .accomodation-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .accomodation-wrapper .accomodation-item {
    flex-wrap: wrap;
    margin-left: 0;
  }
  .accomodation-wrapper .accomodation-item .image {
    width: 100%;
    margin-right: 0;
  }
  .accomodation-wrapper .accomodation-item .image.--hive-image {
    width: 100% !important;
  }
  .accomodation-wrapper .accomodation-item .image img {
    height: 200px;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  .accomodation-wrapper .accomodation-item .desc {
    width: 100%;
  }
  .accomodation-wrapper .accomodation-item .desc div {
    margin-bottom: 15px;
  }
  .accomodation-wrapper .accomodation-item .desc a {
    margin: unset;
  }
}
.booking-link {
  width: 700px;
  max-width: 100%;
  margin: 32px auto 0;
  padding: 40px;
  border: 2px solid #f9c206;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
}

.booking-rating {
  display: table;
  margin: 0 auto 32px;
  padding: 20px;
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  width: 700px;
  max-width: 100%;
}
.booking-rating span.booking-int {
  display: inline-block;
  padding: 10px;
  margin: 5px 10px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  background-color: #003580;
  color: #fff;
  font-size: 2rem;
}
.booking-rating strong {
  color: #003580;
}

.forom-wrapper {
  position: relative;
  z-index: 6;
  display: flex;
  width: 1000px;
  max-width: 100%;
  margin: auto;
  background: #fff;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  margin: 60px auto;
}
.forom-wrapper .form-description,
.forom-wrapper .form-form {
  padding: 30px;
  flex-grow: 1;
}
.forom-wrapper .form-form {
  width: 600px;
}
.forom-wrapper .form-form form {
  display: flex;
  flex-wrap: wrap;
}
.forom-wrapper .form-form form .form-group {
  margin-bottom: 18px;
  flex-grow: 1;
  padding-right: 10px;
  width: 250px;
}
.forom-wrapper .form-form form .form-group.group-full {
  width: 100%;
}
.forom-wrapper .form-form form .form-group label {
  display: block;
  margin-bottom: 6px;
  width: 100%;
  font-weight: 700;
}
.forom-wrapper .form-form form .form-group input[type=text],
.forom-wrapper .form-form form .form-group input[type=email] {
  padding: 12px 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #f9c206;
  width: 100%;
  transition: all 250ms ease-in-out;
}
.forom-wrapper .form-form form .form-group input[type=text]:focus,
.forom-wrapper .form-form form .form-group input[type=email]:focus {
  box-shadow: inset 0 -3px 0 0 #f9c206;
}
.forom-wrapper .form-form form .form-group textarea {
  width: 100%;
  padding: 8px 10px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #f9c206;
  transition: all 250ms ease-in-out;
}
.forom-wrapper .form-form form .form-group textarea:focus {
  box-shadow: inset 0 -3px 0 0 #f9c206;
}
.forom-wrapper .form-form form .form-group .checkboxes-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.forom-wrapper .form-form form .form-group label.chechbox-label {
  display: flex;
  align-items: center;
  width: auto !important;
  padding: 14px 15px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  min-width: 260px;
  border-radius: 8px;
  margin: 10px 18px 10px 0;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}
.forom-wrapper .form-form form .form-group label.chechbox-label input[type=radio],
.forom-wrapper .form-form form .form-group label.chechbox-label input[type=checkbox] {
  display: none;
}
.forom-wrapper .form-form form .form-group label.chechbox-label:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  color: #fff;
  margin-right: 20px;
  background-color: #dddddd;
  border-radius: 5px;
  transition: all 250ms ease-in-out;
}
.forom-wrapper .form-form form .form-group label.chechbox-label.checked {
  border-color: #91b508;
  box-shadow: inset 0 0 0 1px #91b508, 0 7px 10px 0 rgba(0, 0, 0, 0.16);
}
.forom-wrapper .form-form form .form-group label.chechbox-label.checked:before {
  content: "✓";
  line-height: 20px;
  text-align: center;
  background-color: #91b508;
}
.forom-wrapper .form-form form .form-group label.chechbox-label.out-of-stock {
  cursor: not-allowed;
}
.forom-wrapper .form-form form .form-group label.chechbox-label.out-of-stock span.small {
  color: red;
}
.forom-wrapper .form-form form .form-group .label-price {
  white-space: nowrap;
  font-size: 25px;
  text-align: right;
}
.forom-wrapper .form-form form .form-group .label-price .detail-product-sale-original {
  position: absolute;
  bottom: 100%;
  right: 0;
  font-size: 1.1rem;
  display: inline-block;
  background-color: red;
  border-radius: 8px;
  color: #fff;
  padding: 3px 6px;
  position: absolute;
  text-decoration: line-through;
  font-weight: 400;
}
.forom-wrapper .form-form form .form-group .label-volume {
  color: #2d2a24;
  flex-grow: 1;
  font-weight: 400;
  line-height: 1;
}
.forom-wrapper .form-form form .form-group .label-volume span.small {
  display: block;
  width: 100%;
  margin-top: 3px;
  color: #91b508;
}
.forom-wrapper .form-form form .form-group .group-price > div {
  font-size: 3rem;
  font-weight: 700;
}
.forom-wrapper .form-form form #increment_input {
  display: flex;
  justify-content: center;
  width: 150px;
  padding: 14px 15px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
}
.forom-wrapper .form-form form #increment_input input {
  border: none;
  outline: none;
  width: 40px;
  text-align: center;
  padding: 0;
}
.forom-wrapper .form-form form #increment_input span.inc_button {
  content: "-";
  display: block;
  width: 1.3em;
  height: 1.3em;
  color: #2d2a24;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 1.3;
  font-size: 2rem;
  font-weight: 700;
}
.forom-wrapper .form-description {
  width: 400px;
  background-color: #f9c206;
  background-position: right center;
  background-size: cover;
}
.forom-wrapper .form-description p,
.forom-wrapper .form-description div,
.forom-wrapper .form-description a,
.forom-wrapper .form-description ul,
.forom-wrapper .form-description li,
.forom-wrapper .form-description h3 {
  color: #000;
}
.forom-wrapper .form-description h3 {
  font-weight: 700;
}
.forom-wrapper .form-description .form-contact {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.forom-wrapper .form-description .form-contact img {
  margin-right: 10px;
}

.forom-wrapper .form-form form.order-form .form-group {
  width: 50%;
}
.forom-wrapper .form-form form.order-form .form-group.group-full {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.forom-wrapper .form-form form.order-form .form-group.group-full label,
.forom-wrapper .form-form form.order-form .form-group.group-full strong {
  width: 100%;
}
.forom-wrapper .form-form form.order-form .form-group.group-full.w-price {
  align-items: flex-start;
}

#genericForm_forms_flash,
#productForm_forms_flash {
  width: 100%;
}
#genericForm_forms_flash .alert,
#productForm_forms_flash .alert {
  color: #155724;
  background-color: #d4edda;
  padding: 5px;
  display: table;
  margin: auto;
  text-align: center;
  width: 100%;
}
#genericForm_forms_flash .alert p,
#productForm_forms_flash .alert p {
  margin: 0;
}
#genericForm_forms_flash .alert li,
#productForm_forms_flash .alert li {
  margin: 5px auto;
}
#genericForm_forms_flash .alert ul,
#productForm_forms_flash .alert ul {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  margin: 5px;
}
#genericForm_forms_flash .alert.alert-danger,
#productForm_forms_flash .alert.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
}
#genericForm_forms_flash .alert .close,
#productForm_forms_flash .alert .close {
  display: none;
  margin: 0 0 0 auto;
  border: none;
}
#genericForm_forms_flash .alert .close span,
#productForm_forms_flash .alert .close span {
  font-size: 16px;
  color: #721c24;
}

@media (max-width: 991px) {
  .forom-wrapper {
    flex-wrap: wrap;
  }
  .forom-wrapper .form-description,
  .forom-wrapper .form-form {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .forom-wrapper {
    max-width: calc(100% + 30px);
    margin: 20px -15px;
  }
}
.price-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 10px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}
.price-list-item h4 {
  font-weight: 700;
}

/* Colors */
/* Menu */
.api-list .normal-price {
  text-decoration: line-through;
  color: #b4b4b4;
}

.certification {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.certification.--voucher .certification-item {
  width: 350px;
}
@media (max-width: 555px) {
  .certification.--voucher .certification-item {
    width: 100%;
  }
}
.certification.--voucher .certification-item .image {
  min-height: auto;
}
.certification.--voucher .certification-item .image a {
  min-height: auto;
}

.certification-item {
  width: 350px;
  max-width: 100%;
  background-color: #fff;
  margin: 10px;
}
.certification-item.--api {
  background-color: transparent;
}
.certification-item.--voucher {
  padding: 16px;
  background-color: #fff;
  width: 800px;
  max-width: 100%;
  margin: 0 auto 48px;
}
.certification-item.--voucher .image {
  min-height: auto;
}
.certification-item.--voucher .image a {
  min-height: auto;
}
.certification-item .image {
  min-height: 500px;
}
.certification-item .image a {
  display: flex;
  height: 100%;
  min-height: 500px;
  align-items: center;
}
.certification-item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.certification-item .description {
  padding: 20px;
}
.certification-item .description h3 {
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.4;
  color: #333;
  text-transform: uppercase;
}

/* Colors */
/* Menu */
header#mainMenu .lang-select {
  margin-left: 14px;
}
header#mainMenu .locate-select-menu {
  margin-left: 0;
}
header#mainMenu .language {
  position: relative;
  display: flex;
  padding: 10px 10px;
  list-style-type: none;
}
header#mainMenu .language:hover .form-control, header#mainMenu .language:focus .form-control {
  display: block;
}
header#mainMenu .language .lang {
  position: relative;
  transition: all 250ms ease-in-out;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px 10px 10px;
  margin-right: 0;
}
header#mainMenu .language .lang::before {
  display: none;
}
header#mainMenu .language .lang::after {
  content: url(../img/arrow-down.svg);
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
}
header#mainMenu .language .lang:hover {
  color: #000;
  background-color: #fff;
}
header#mainMenu .language .lang:hover::after {
  filter: invert(1);
}
header#mainMenu .form-control {
  display: none;
  cursor: pointer;
  position: absolute;
  left: 12px;
  top: 100%;
  background-color: #f9c206;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  margin-right: 11px;
}
header#mainMenu .form-control li {
  padding: 10px 0;
  color: #fff;
  margin-left: 0;
  text-align: center;
}

input#switchLocate-cz,
input#switchLocate-en,
input#switchLocate-de {
  display: none;
}

input[type=radio] + label {
  color: #fff;
  cursor: pointer;
}
input[type=radio] + label:hover {
  color: #000;
}

input[type=radio]:checked + label {
  color: #000;
}

@media (max-width: 991px) {
  header#mainMenu .form-control {
    top: 100%;
    left: 6px;
    padding: 22px;
  }
  header#mainMenu .language .lang {
    color: #000;
    border: 2px solid #000;
  }
  header#mainMenu .language .lang::after {
    filter: invert(1);
  }
}
/**
 * Lightcase - jQuery Plugin
 * The smart and flexible Lightbox Plugin.
 *
 * @author		Cornel Boppart <cornel@bopp-art.com>
 * @copyright	Author
 *
 * @version		2.5.0 (11/03/2018)
 */
/**
 * Lightcase settings
 *
 * Note: Override default settings for your project without touching this source code by simply
 * defining those variables within a SASS map called '$lightcase-custom'.
 *
 * // Example usage
 * $lightcase-custom: (
 *   'breakpoint': 768px
 * );
 */
@font-face {
  font-family: "lightcase";
  src: url("../../fonts/lightcase/lightcase.eot?55356177");
  src: url("../../fonts/lightcase/lightcase.eot?55356177#iefix") format("embedded-opentype"), url("../../fonts/lightcase/lightcase.woff?55356177") format("woff"), url("../../fonts/lightcase/lightcase.ttf?55356177") format("truetype"), url("../../fonts/lightcase/lightcase.svg?55356177#lightcase") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*=lightcase-icon-]:before {
  font-family: "lightcase", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* Codes */
.lightcase-icon-play:before {
  content: "\e800";
}

.lightcase-icon-pause:before {
  content: "\e801";
}

.lightcase-icon-close:before {
  content: "\e802";
}

.lightcase-icon-prev:before {
  content: "\e803";
}

.lightcase-icon-next:before {
  content: "\e804";
}

.lightcase-icon-spin:before {
  content: "\e805";
}

/**
 * Mixin providing icon defaults to be used on top of font-lightcase.
 *
 * Example usage:
 * @include icon(#e9e9e9)
 */
/**
 * Mixin providing icon defaults including a hover status to be used
 * on top of font-lightcase.
 *
 * Example usage:
 * @include icon-hover(#e9e9e9, #fff)
 */
/**
 * Provides natural content overflow behavior and scrolling support
 * even so for touch devices.
 *
 * Example usage:
 * @include overflow()
 */
/**
 * Neutralizes/resets dimensions including width, height, position as well as margins,
 * paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
 *
 * Example usage:
 * @include clear(true)
 *
 * @param boolean $important
 */
@-webkit-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-moz-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
#lightcase-case {
  display: none;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-case, html[data-lc-type=ajax] #lightcase-case {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    padding: 55px 0 70px 0;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
  }
}

@media screen and (min-width: 641px) {
  html:not([data-lc-type=error]) #lightcase-content {
    position: relative;
    z-index: 1;
    text-shadow: none;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -webkit-backface-visibility: hidden;
  }
}
@media screen and (min-width: 641px) {
  html[data-lc-type=image] #lightcase-content, html[data-lc-type=video] #lightcase-content {
    background-color: #fff;
  }
}
html[data-lc-type=inline] #lightcase-content, html[data-lc-type=ajax] #lightcase-content, html[data-lc-type=error] #lightcase-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content, html[data-lc-type=ajax] #lightcase-content, html[data-lc-type=error] #lightcase-content {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: none !important;
  }
}
html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner, html[data-lc-type=error] #lightcase-content .lightcase-contentInner {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner, html[data-lc-type=error] #lightcase-content .lightcase-contentInner {
    padding: 15px;
  }
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=inline] #lightcase-content .lightcase-contentInner > *, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner > *, html[data-lc-type=error] #lightcase-content .lightcase-contentInner, html[data-lc-type=error] #lightcase-content .lightcase-contentInner > * {
    width: 100% !important;
    max-width: none !important;
  }
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner > *:not(iframe), html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner > *:not(iframe), html[data-lc-type=error] #lightcase-content .lightcase-contentInner > *:not(iframe) {
    height: auto !important;
    max-height: none !important;
  }
}
@media screen and (max-width: 640px) {
  html.lightcase-isMobileDevice[data-lc-type=iframe] #lightcase-content .lightcase-contentInner iframe {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (max-width: 640px) and (min-width: 641px) {
  html[data-lc-type=image] #lightcase-content .lightcase-contentInner, html[data-lc-type=video] #lightcase-content .lightcase-contentInner {
    line-height: 0.75;
  }
}
html[data-lc-type=image] #lightcase-content .lightcase-contentInner {
  position: relative;
  overflow: hidden !important;
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap, html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap, html[data-lc-type=error] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: none !important;
  }
}
@media screen and (min-width: 641px) {
  html:not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    padding: 30px;
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 640px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #aaa;
  }
}
@media screen and (min-width: 641px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #333;
  }
}

#lightcase-case p.lightcase-error {
  margin: 0;
  font-size: 17px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  #lightcase-case p.lightcase-error {
    padding: 30px 0;
  }
}
@media screen and (min-width: 641px) {
  #lightcase-case p.lightcase-error {
    padding: 0;
  }
}

.lightcase-open body {
  overflow: hidden;
}
.lightcase-isMobileDevice .lightcase-open body {
  max-width: 100%;
  max-height: 100%;
}

#lightcase-info {
  position: absolute;
  padding-top: 15px;
}
#lightcase-info #lightcase-title,
#lightcase-info #lightcase-caption {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-weight: normal;
  text-overflow: ellipsis;
}
#lightcase-info #lightcase-title {
  font-size: 17px;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  #lightcase-info #lightcase-title {
    position: fixed;
    top: 10px;
    left: 0;
    max-width: 87.5%;
    padding: 5px 15px;
    background: #333;
  }
}
#lightcase-info #lightcase-caption {
  clear: both;
  font-size: 13px;
  color: #aaa;
}
#lightcase-info #lightcase-sequenceInfo {
  font-size: 11px;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  .lightcase-fullScreenMode #lightcase-info {
    padding-left: 15px;
    padding-right: 15px;
  }
  html:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-info {
    position: static;
  }
}

#lightcase-loading {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  opacity: 1;
  font-size: 32px;
  text-shadow: 0 0 15px #fff;
  -moz-transform-origin: 50% 53%;
  -webkit-animation: lightcase-spin 0.5s infinite linear;
  -moz-animation: lightcase-spin 0.5s infinite linear;
  -o-animation: lightcase-spin 0.5s infinite linear;
  animation: lightcase-spin 0.5s infinite linear;
}
#lightcase-loading, #lightcase-loading:focus {
  text-decoration: none;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
#lightcase-loading > span {
  display: inline-block;
  text-indent: -9999px;
}

a[class*=lightcase-icon-] {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  outline: none;
  cursor: pointer;
}
a[class*=lightcase-icon-], a[class*=lightcase-icon-]:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
a[class*=lightcase-icon-] > span {
  display: inline-block;
  text-indent: -9999px;
}
a[class*=lightcase-icon-]:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 15px rgb(255, 255, 255);
}
.lightcase-isMobileDevice a[class*=lightcase-icon-]:hover {
  color: #aaa;
  text-shadow: none;
}
a[class*=lightcase-icon-].lightcase-icon-close {
  position: fixed;
  top: 15px;
  right: 15px;
  bottom: auto;
  margin: 0;
  opacity: 0;
  outline: none;
}
a[class*=lightcase-icon-].lightcase-icon-prev {
  left: 15px;
}
a[class*=lightcase-icon-].lightcase-icon-next {
  right: 15px;
}
a[class*=lightcase-icon-].lightcase-icon-pause, a[class*=lightcase-icon-].lightcase-icon-play {
  left: 50%;
  margin-left: -0.5em;
}
@media screen and (min-width: 641px) {
  a[class*=lightcase-icon-].lightcase-icon-pause, a[class*=lightcase-icon-].lightcase-icon-play {
    opacity: 0;
  }
}
@media screen and (max-width: 640px) {
  a[class*=lightcase-icon-] {
    bottom: 15px;
    font-size: 24px;
  }
}
@media screen and (min-width: 641px) {
  a[class*=lightcase-icon-] {
    bottom: 50%;
    margin-bottom: -0.5em;
  }
  a[class*=lightcase-icon-]:hover, #lightcase-case:hover ~ a[class*=lightcase-icon-] {
    opacity: 1;
  }
}

#lightcase-overlay {
  display: none;
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 2000;
  top: -9999px;
  bottom: -9999px;
  left: 0;
  background: #333;
}
@media screen and (max-width: 640px) {
  #lightcase-overlay {
    opacity: 1 !important;
  }
}