@import "../variables";

// After scroll
header#mainMenu.scrolled {
	position: fixed;
	background-color: $nav-bg-after-scroll;
	box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
	.logo {
		img {
			filter: none;
		}
	}
	.hamburger {
		span {
			background-color: #333;
		}
	}
	ul.navigation {
		li {
			a {
				color: $nav-link-color-after-scroll;
				&:hover {
					color: $nav-link-color-hover-after-scroll;
				}
			}
		}
	}
}
