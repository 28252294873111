@import '../variables';
.first-section {
	background-image: url("../img/footer-bg.png");
	padding-top: 250px;
	background-color: $third-color;
	.footer-wrapper {
		.logo-contact {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 50px;
			.logo {
				img {
					height: 80px;
					width: auto;
				}
			}
			.contact {
				display: flex;
				a {
					display: block;
					margin-right: 20px;
					&:last-child {
						margin-right: 0;
					}
				}
				div {
					position: relative;
					padding-left: 30px;
					color: #fff;
					margin-right: 20px;
					&::before {
						position: absolute;
						content: "";
						width: 25px;
						height: 25px;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
					}
					&.address {
						&::before {
							background-image: url("../img/marker.svg");
						}
					}
					&.phone {
						&::before {
							background-image: url("../img/phone.svg");
						}
					}
					&.mail {
						&::before {
							background-image: url("../img/mail.svg");
						}
					}
				}
			}
		}
		.menu-socials {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 50px;
			.menu {
				display: flex;
				a {
					display: block;
					margin-right: 30px;
					color: #fff;
					font-weight: 700;
					&:hover {
						color: $base-color;
					}
				}
			}
			.socials {
				img {
					width: 55px;
					height: auto;
					margin-right: 10px;
				}
			}
		}
	}
}

.second-section {
	background-color: #4d3b1f;
	position: relative;
	padding: 20px;
	&::before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.14);
	}
	div {
		text-align: center;
		color: #fff;
	}
}

@media (max-width: $bp-sm - 1) {
	.first-section {
		background-image: url("../img/footer-bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 250px;
		background-color: $third-color;
		.footer-wrapper {
			.logo-contact {
				margin-bottom: 20px;
				flex-wrap: wrap;
				.logo {
					width: 100%;
					margin-bottom: 40px;
					img {
						margin: auto;
						display: block;
					}
				}
				.contact {
					display: block;
					margin: auto;

					a {
						margin: auto;
						display: table;
					}
					div {
						margin: auto;
						margin-bottom: 40px;
					}
				}
			}
			.menu-socials {
				flex-wrap: wrap;
				.menu {
					width: 100%;
					display: block;
					margin-bottom: 20px;
					a {
						margin: auto;
						display: table;
						margin-bottom: 15px;
					}
				}
				.socials {
					margin: auto;
				}
			}
		}
	}
}
