.certification {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	&.--voucher{
		.certification-item{
			width: 350px;
			@media (max-width: $bp-xxs - 1) {
				width: 100%;
			}
			.image{
				min-height: auto;
				a{
					min-height: auto;
				}
			}
		}
	}
}

.certification-item {
	width: 350px;
	max-width: 100%;
	background-color: #fff;
	margin: 10px;
	&.--api{
		background-color: transparent;
	}
	&.--voucher{
		padding: 16px;
		background-color: #fff;
		width: 800px;
		max-width: 100%; 
		margin: 0 auto 48px;
		.image{
			min-height: auto;
			a{
				min-height: auto;
			}
		}
	}
	.image{
		min-height: 500px;
		a{
			display: flex;
			height: 100%;
			min-height: 500px;
			align-items: center;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	.description {
		padding: 20px;
		h3 {
			text-align: center;
			font-size: 1.8rem;
			line-height: 1.4;
			color: #333;
			text-transform: uppercase;
		}
	}
}
