@import '../variables';
.contact-wrapper {
	display: flex;
	.contact-info {
		width: 35%;
		margin-right: 30px;
		h2 {
			text-align: left;
			font-size: 3rem;
			padding-bottom: 15px;
			margin-bottom: 35px;
			&::before {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				transform: unset;
				width: 60px;
				height: 3px;
				border-radius: 30px;
				background-color: $base-color;
			}
		}

		.icon {
			position: relative;
			padding-left: 45px;
			margin-bottom: 40px;
			font-size: 2rem;

			&::before {
				position: absolute;
				content: "";
				width: 40px;
				height: 40px;
				left: 0;
				top: 50%;
				transform: translate(0, -50%);

				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}
			&.marker {
				&::before {
					background-image: url("../img/marker-contact.svg");
				}
			}
			&.phone {
				&::before {
					background-image: url("../img/phone-contact.svg");
				}
			}
			&.email {
				&::before {
					background-image: url("../img/mail-contact.svg");
				}
			}
		}
		h3 {
			font-weight: 700;
		}
		.ico-contact {
			opacity: 0.6;
		}
	}
	.contact-map {
		position: relative;
		width: 65%;
		height: 500px;
		&::before {
			content: "";
			position: absolute;
			//background-image: url("../img/marker-contact-map.svg");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			width: 60px;
			height: 60px;
		}
		a.gotomap {
			display: block;
			width: 100%;
			height: 100%;
			background-image: url("../img/map.jpg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			border-radius: 15px;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				background-color: #000;
				width: 100%;
				height: 100%;
				transition: all 250ms ease-in-out;
				border-radius: 15px;
			}

			&:hover {
				&::before {
					opacity: 0.6;
				}
				span {
					opacity: 1;
				}
			}
			span {
				position: absolute;
				font-size: 3rem;
				top: 50%;
				left: 50%;
				opacity: 0;
				transition: all 250ms ease-in-out;
				transform: translate(-50%, -50%);
				color: #fff;
				border-radius: 15px;
			}
		}
	}
}
@media (max-width: $bp-sm - 1) {
	.contact-wrapper {
		flex-wrap: wrap;
		.contact-info {
			width: 100%;
			margin-right: 0;
			margin-bottom: 30px;
		}
		.contact-map {
			width: 100%;
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.contact-wrapper {
		.contact-map {
			height: 300px;
		}
	}
}
