@import '../variables';
.our-offer-wrapper {
	display: flex;
	gap: 8px;
	position: relative;
	justify-content: center;
	flex-wrap: wrap;
	&::before {
		position: absolute;
		content: "";
		bottom: -56px;
		left: -56px;
		width: 140px;
		height: 160px;
		background-image: url("../img/bee-offer.png");
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	.our-offer-item {
		width: calc(33.333% - 8px);
		
		height: 600px;
		border-radius: 10px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 8px;
		position: relative;
		overflow: hidden;
		@media (max-width: $bp-md - 1) {
			height: 400px;
		}
		@media (max-width: $bp-sm - 1) {
			width: calc(50% - 8px);
		}
		@media (max-width: $bp-xs - 1) {
			width: 100%;
		}
		@media (max-width: $bp-xxs - 1) {
			height: 350px;
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			top: 0;
			left: 0;
			border-radius: 10px;
			transition: all 250ms ease-in-out;
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
		&::before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
			border-radius: 10px;
			z-index: 1;
		}
		h3 {
			position: relative;
			color: #fff;
			font-weight: 700;
			margin-bottom: 8px;
			z-index: 2;
		}
		p {
			position: relative;
			z-index: 2;
			font-size: 1.4rem;
			color: #fff;
			opacity: 0.6;
			margin-bottom: 0;
			line-height: 1.3;
		}
		a.news-btn {
			position: relative;
			z-index: 2;
			padding-right: 50px;
			display: table;
			margin: 0 0 0 auto;
			position: relative;
			font-weight: 700;

			&::before {
				position: absolute;
				content: "";
				width: 20px;
				height: 20px;
				background-image: url("../img/arrow-right.svg");
				right: 25px;
				top: 50%;
				transform: translate(0, -50%);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				transition: all 250ms ease-in-out;
			}
			&:hover {
				&::before {
					right: 20px;
				}
			}
		}
	}
}
