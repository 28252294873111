@import '../variables';
.news-hp {
	background-color: #fff;
	padding: 40px;
	border-radius: 10px;
	h3 {
		position: relative;
		padding-bottom: 15px;
		font-weight: 700;
		margin-bottom: 25px;
		&::before {
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;

			width: 50px;
			height: 3px;
			border-radius: 30px;
			background-color: $base-color;
		}
	}
	.news-wrapper-hp {
		.news-item-hp {
			position: relative;
			transition: all 250ms ease-in-out;
			border-bottom: 1px solid #eee;
			padding-bottom: 20px;
			margin-bottom: 20px;
			&:hover {
				h4 {
					text-decoration: underline;
				}
			}
			.date {
				color: $base-color;
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: 10px;
				div {
					font-size: 1.4rem;
				}
			}
			h4 {
				font-weight: 700;
				font-size: 2rem;
				margin-bottom: 15px;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}
.news-wrapper {
	display: flex;
	flex-wrap: wrap;
	.news-item {
		width: calc(25% - 30px);
		margin-right: 10px;
		border-radius: 10px;
		margin-bottom: 20px;
		background-color: #fff;
		transition: all 250ms ease-in-out;
		&:hover {
			transform: translate(0, -10px);
			box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .16);
		}

		.news-image {
			width: 100%;
			height: 200px;
			img {
				border-radius: 10px;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.desc {
			.date {
				display: table;
				margin: 0 0 0 auto;
				background-color: $base-color;
				padding: 8px 10px;
				border-radius: 10px;
				margin-bottom: 15px;
			}
			padding: 15px 20px;
			h3 {
				font-weight: 700;
				font-size: 2.3rem;
			}
			a {
				padding-right: 25px;
				display: table;
				margin: 0 0 0 auto;
				position: relative;
				font-weight: 700;

				&::before {
					position: absolute;
					content: "";
					width: 20px;
					height: 20px;
					background-image: url("../img/arrow-right.svg");
					right: 0px;
					top: 50%;
					transform: translate(0, -50%);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					transition: all 250ms ease-in-out;
				}
				&:hover {
					&::before {
						right: -5px;
					}
				}
			}
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
}
.news-detail-wrapper {
	display: flex;
	.image {
		width: 35%;
		height: 300px;
		margin-right: 30px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 10px;
		}
	}
	.desc {
		width: 65%;
		.date {
			display: table;
			background-color: $base-color;
			padding: 8px 10px;
			border-radius: 10px;
			margin-bottom: 15px;
		}
		h2 {
			text-align: left;
			&::before {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				transform: unset;
				width: 100px;
				height: 3px;
				border-radius: 30px;
				background-color: $base-color;
			}
		}
	}
}
@media (max-width: $bp-lg - 1) {
	.news-wrapper {
		display: flex;
		flex-wrap: wrap;
		.news-item {
			width: calc(33.333% - 20px);

			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(4n) {
				margin-right: 10px;
			}
		}
	}
}
@media (max-width: $bp-md - 1) {
	.news-wrapper-hp {
		display: flex;
		flex-wrap: wrap;
		.news-item-hp {
			width: calc(50% - 10px);
			margin-right: 10px;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}
@media (max-width: $bp-sm - 1) {
	.news-wrapper {
		display: flex;
		flex-wrap: wrap;
		.news-item {
			width: calc(50% - 10px);
			&:nth-child(2n) {
				margin-right: 0px;
			}
			&:nth-child(3n) {
				margin-right: 10px;
			}
			&:nth-child(4n) {
				margin-right: 10px;
			}
		}
	}
}
@media (max-width: $bp-xs - 1) {
	.news-detail-wrapper {
		flex-wrap: wrap;
		.image {
			width: 100%;
			height: 300px;
			margin-right: 0;
			margin-bottom: 30px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 10px;
			}
		}
		.desc {
			width: 100%;
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.news-hp {
		padding: 15px;
		.news-wrapper-hp {
			display: flex;
			flex-wrap: wrap;
			.news-item-hp {
				width: 100%;
				margin-right: 0;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}
	.news-wrapper {
		display: flex;
		flex-wrap: wrap;
		.news-item {
			width: 100%;
			margin-right: 0;
			&:nth-child(2n) {
				margin-right: 0px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}
}
