@import '../variables';
.banner-wrapper {
	position: relative;
	//height: 80vh;
	background-image: url("../img/webp/banner.webp"), url("../img/banner.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
	.banner-desc {
		padding-top: 160px;
		padding-bottom: 180px;
		position: relative;
		z-index: 4;
		width: 55%;
		h1 {
			text-align: left;
			color: #fff;
			font-weight: 700;
			text-transform: uppercase;
			white-space: nowrap;
			line-height: 1.1;
			padding-bottom: 0;
			margin-bottom: 15px;
			&::before {
				display: none;
			}
		}
		p {
			color: #fff;
			font-size: 2rem;
			margin-bottom: 40px;
		}
		.btn-std {
			padding: 20px 40px;
		}
	}
	&::before {
		position: absolute;
		content: "";
		width: 100%;
		height: 50%;
		bottom: -4px;
		left: 0%;
		background-image: url("../img/banner-wave.png");
		background-size: 100% auto;
		background-position: center bottom;
		background-repeat: no-repeat;
		z-index: 2;
	}
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.17) 100%);
		opacity: 0.35;
	}
}
@media (max-width: $bp-md - 1) {
	/*.banner-wrapper {
		&::before {
			position: absolute;
			content: "";
			width: 105%;
			height: 35%;
			bottom: 0;
			left: -2%;
			background-image: url("../img/banner-hp-before.png");
			background-size: cover;
			background-position: top;
			background-repeat: no-repeat;
			z-index: 2;
		}
	}*/
}
@media (max-width: $bp-sm - 1) {
	.banner-wrapper {
		.banner-desc {
			width: 100%;
			padding-top: 170px;
			h1 {
				white-space: unset;
				font-size: 4rem;
			}
			p {
				font-size: 1.8rem;
			}
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.banner-wrapper {
		background-position: right center;
		p {
			display: none;
		}
		.banner-desc {
			width: 100%;
			padding-top: 100px;
			padding-bottom: 100px;
			h1 {
				white-space: unset;
				font-size: 2.4rem;
				line-height: 1.4;
			}
			p {
				font-size: 1.6rem;
			}
			.btn-std {
				padding: 20px 20px;
			}
		}
	}
}
