@import "../_variables.scss";
section.global-section {
	padding: 60px 0;
}
section.color-section {
	padding: 60px 0;
	background-color: #f8f3f0;
	overflow: hidden;
	strong {
		color: $sec-color;
		font-weight: 700;
	}
	ul {
		li {
			color: $sec-color;
		}
	}
}
.bee-section {
	position: relative;
	h2 {
		position: relative;
		z-index: 10;
	}
	&::before {
		position: absolute;
		content: "";
		background-image: url("../img/bee-hp.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		right: -100px;
		top: 0;
		width: 300px;
		height: 300px;
		overflow: hidden;
	}
}
.honey-section {
	position: relative;
	h2 {
		position: relative;
		z-index: 10;
	}
	&::after {
		position: absolute;
		content: "";
		background-image: url("../img/honey-hp.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		left: -150px;
		top: 0;
		width: 300px;
		height: 300px;
		overflow: hidden;
	}
}
section.bee-honey-section {
	position: relative;
	padding-top: 0;
	h2 {
		position: relative;
		z-index: 10;
	}
	&::before {
		position: absolute;
		content: "";
		background-image: url("../img/bee-hp.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		right: 0;
		top: 0;
		width: 300px;
		height: 300px;
	}
	&::after {
		position: absolute;
		content: "";
		background-image: url("../img/honey-hp.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		left: -150px;
		top: 0;
		width: 300px;
		height: 300px;
		overflow: hidden;
	}
	&.honey-bee-hp {
		&::before {
			right: -150px;
			overflow: hidden;
			top: 60%;
		}
		&::after {
			background-image: url("../img/honey-hp-news.png");
			top: 25%;
			left: -200px;
			width: 400px;
			height: 400px;
		}
	}
}

@media (max-width: $bp-xs - 1) {
	section.bee-honey-section {
		padding-top: 50px;
	}
}

.p-middle {
	width: 700px;
	max-width: 100%;
	margin: auto;
	text-align: center;
	margin-bottom: 60px;
	span{
		display: inline-block;
	}
}
@media (max-width: $bp-md - 1) {
	.bee-honey-section {
		&::before {
			width: 180px;
		}
		&::after {
			width: 220px;
		}
	}
}
@media (max-width: $bp-xs - 1) {
	.bee-section {
		&::before {
			width: 180px;
			display: none;
		}
	}
	.bee-honey-section {
		&::before {
			width: 180px;
			display: none;
		}
		&::after {
			width: 220px;
			display: none;
		}
	}
	.honey-section {
		&::after {
			width: 220px;
			display: none;
		}
	}
}
