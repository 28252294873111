@import '../variables';
header#mainMenu {
	.lang-select {
		margin-left: 14px;
	}
	.locate-select-menu {
		margin-left: 0;
	}
	.language {
		position: relative;
		display: flex;
		padding: 10px 10px;
		list-style-type: none;
		&:hover,
		&:focus {
			.form-control {
				display: block;
			}
		}
		.lang {
			position: relative;
			transition: all 250ms ease-in-out;
			border: 2px solid #fff;
			color: #fff;
			padding: 10px 20px 10px 10px;
			margin-right: 0;
			&::before {
				display: none;
			}

			&::after {
				content: url(../img/arrow-down.svg);
				position: absolute;
				top: 50%;
				right: 7px;
				transform: translateY(-50%);
			}

			&:hover {
				color: #000;
				background-color: #fff;
				&::after {
					filter: invert(1);
				}
			}
		}
	}
	.form-control {
		display: none;
		cursor: pointer;
		position: absolute;
		left: 12px;
		top: 100%;
		background-color: $base-color;
		text-transform: uppercase;
		padding: 0;
		border: 0;
		margin-right: 11px;
		li {
			padding: 10px 0;
			color: #fff;
			margin-left: 0;
			text-align: center;
		}
	}
}

input#switchLocate-cz,
input#switchLocate-en,
input#switchLocate-de {
	display: none;
}
input[type=radio] + label {
	color: #fff;
	cursor: pointer;
	&:hover {
		color: #000;
	}
}
input[type=radio]:checked + label {
	color: #000;
}
@media (max-width: $nav-bp) {
	header#mainMenu {
		.form-control {
			top: 100%;
			left: 6px;
			padding: 22px;
		}
		.language {
			.lang {
				color: #000;
				border: 2px solid #000;
				&::after {
					filter: invert(1);
				}
			}
		}
	}
}
