@import "../_variables.scss";
html {
	font-size: 10px;
}
body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: $text;
}
textarea,
button,
input {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}
h1, .h1 {
	position: relative;
	font-family: 'Montserrat', sans-serif;
	font-size: 6rem;
	font-weight: 700;
	margin-bottom: 30px;
	padding-bottom: 30px;
	line-height: 1;
	text-align: center;
	color: $sec-color;
	&::before {
		position: absolute;
		content: "";
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		width: 100px;
		height: 3px;
		background-color: $base-color;
		border-radius: 30px;
	}
}
h2, .h2 {
	position: relative;
	font-family: 'Montserrat', sans-serif;
	font-size: 4.4rem;
	margin-bottom: .5em;
	font-weight: 700;
	line-height: 1.1;
	text-align: center;
	color: $sec-color;
	padding-bottom: 30px;
	&::before {
		position: absolute;
		content: "";
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		width: 100px;
		height: 3px;
		border-radius: 30px;
		background-color: $base-color;
	}
	&.text-left {
		text-align: left;
		&::before {
			left: 0;
			transform: unset;
		}
	}
}
h3, .h3 {
	font-family: 'Montserrat', sans-serif;
	font-size: 2.8rem;
	position: relative;
	font-weight: 700;
	margin-bottom: 0.6em;
	line-height: 1.1;
	color: $sec-color;
}
p {
	font-size: 1.6rem;
	line-height: 1.6;
	margin-bottom: 20px;
	//color: $sec-color;
	color: rgba($color: $sec-color, $alpha: 1);
	//opacity: 0.6;
}
div,
ul,
ol,
table {
	font-size: 1.6rem;
	color: $sec-color;
}

main {
	ul {
		list-style-type: disc;
		padding-left: 20px;
		line-height: 1.6;
		li {
			margin-bottom: 10px;
			line-height: 1.6;
		}
	}
	div {
		line-height: 1.6;
	}
}
a,
li,
td,
span,
input,
textarea,
label,
th {
	font-size: inherit;
}
// Font responsive
@media screen and (max-width: $bp-xs - 1) {
	h1 {
		font-size: 3.8rem;
	}
	h2 {
		font-size: 2.6rem;
		margin-bottom: 1.5em;
		padding-bottom: .5em;
	}
	h3 {
		font-size: 2.2rem;
		margin-bottom: .5em;
	}
}
.text-red {
	color: #f13232 !important;
}
