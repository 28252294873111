/* Global styles TOC
	Reseter
	Fonts
	Typo
	Global classes
	Bootstrap alter
*/
@import "../_variables.scss";

/* Typo */
button,
input[type="submit"] {
	cursor: pointer;
}

* {
	outline: none;
}
a {
	text-decoration: none;
	transition: 200ms ease-in-out;
	color: $base-color;
}
li {
	font-size: inherit;
}
img {
	max-width: 100%;
	height: auto;
}
strong {
	font-weight: 700;
}
img.no-resize {
	max-width: none;
}

body {
	.fs-40 {
		font-size: 40px;
	}
	.fs-36 {
		font-size: 36px;
	}
	.fs-34 {
		font-size: 34px;
	}
	.fs-30 {
		font-size: 30px;
	}
	.fs-24 {
		font-size: 24px;
	}
	.fs-20 {
		font-size: 20px;
	}
	.fs-18 {
		font-size: 18px;
	}
	.fs-16 {
		font-size: 16px;
	}
	.fs-15 {
		font-size: 15px;
	}
	.fs-14 {
		font-size: 14px;
	}
	.fw-300 {
		font-weight: 300;
	}
	.fw-400 {
		font-weight: 400;
	}
	.fw-500 {
		font-weight: 500;
	}
	.fw-700 {
		font-weight: 700;
	}

	.text-center {
		text-align: center;
	}
	.text-left {
		text-align: left;
	}
	.text-right {
		text-align: right;
	}
	.td-underline {
		text-decoration: underline;
	}
	.text-green {
		color: $base-color;
	}
	.monserat {
		font-family: 'Montserrat', sans-serif;
	}
}
.no-border {
	border: none !important;
}
.small {
	font-size: .8em;
}
.big {
	font-size: 1.6em;
}
.nowrap {
	white-space: nowrap;
}
.turncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media (min-width: $bp-sm) {
	.turncate-sm {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.w-100 {
	width: 100%;
}
.uppercase {
	text-transform: uppercase;
}
.td-under {
	text-decoration: underline;
}
.mb-1 {
	margin-bottom: 25px;
}
.mb-10 {
	margin-bottom: 10px;
}
/* Global classes */
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.jc-center {
	justify-content: center;
}
.jc-start {
	justify-content: flex-start;
}
.jc-end {
	justify-content: flex-end;
}
.ac-center {
	align-content: center;
}
.ai-center {
	align-items: center;
}
.ai-end {
	align-items: flex-end;
}
.jc-space-between {
	justify-content: space-between;
}
.row {
	display: flex;
	flex-wrap: wrap;
	.col {
		flex-grow: 1;
	}
	.col-ng {
		flex-grow: 0;
	}
}
.ma {
	margin: auto;
}
.no-margin {
	margin: 0;
}
.no-margin-left {
	margin-left: 0;
}
.no-margin-right {
	margin-right: 0;
}
.no-margin-top {
	margin-top: 0;
}
.no-margin-bottom {
	margin-bottom: 0;
}
.no-padding {
	padding: 0;
}
.no-padding-left {
	padding-left: 0;
}
.no-padding-right {
	padding-right: 0;
}
.no-padding-top {
	padding-top: 0;
}
.no-padding-bottom {
	padding-bottom: 0;
}
.flex {
	display: flex;
}
.flex-grow {
	flex-grow: 1;
}
.flex-wrap {
	flex-wrap: wrap;
}

/* Own bootstrap alternative */
.container-small,
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	max-width: 100%;
	width: 100%;
}
@for $i from 1 through 12 {
	.col-#{$i} {
		padding: 0 10px;
		width: calc((#{$i} / 12) * 100%);
	}
}
@media (min-width: $bp-xs) {
	.container-small,
	.container {
		width: 750px;
	}
	@for $i from 1 through 12 {
		.col-xs-#{$i} {
			width: calc((#{$i} / 12) * 100%);
		}
	}
}
@media (min-width: $bp-sm) {
	.container-small,
	.container {
		width: 970px;
	}
	@for $i from 1 through 12 {
		.col-sm-#{$i} {
			width: calc((#{$i} / 12) * 100%);
		}
	}
}
@media (min-width: $bp-md) {
	.container-small,
	.container {
		width: 1170px;
	}
	@for $i from 1 through 12 {
		.col-md-#{$i} {
			width: calc((#{$i} / 12) * 100%);
		}
	}
}
@media (min-width: $bp-lg) {
	.container-small,
	.container {
		width: 1400px;
	}
	@for $i from 1 through 12 {
		.col-lg-#{$i} {
			width: calc((#{$i} / 12) * 100%);
		}
	}
}

table {
	margin-bottom: 20px;
	font-size: 16px;
	td {
		padding: 10px 25px;
	}
}
.table-stripped {
	tr:nth-child(odd) {
		background: #f2f2f2;
	}
}
.table-full {
	width: 100%;
}
@media (max-width: $bp-xxs - 1) {
	.hidden-xxs-down {
		display: none;
	}
	table {
		font-size: 14px;
		td {
			padding: 10px;
		}
	}
}
@media (max-width: $bp-xs - 1) {
	.hidden-xs-down {
		display: none;
	}
}
@media (max-width: $bp-sm - 1) {
	.hidden-sm-down {
		display: none;
	}
}
@media (max-width: $bp-md - 1) {
	.hidden-md-down {
		display: none;
	}
}
@media (max-width: $bp-lg - 1) {
	.hidden-lg-down {
		display: none;
	}
}
.d-none {
	display: none;
	visibility: hidden;
	opacity: 0;
	z-index: -2000;
}
.visible-only-xxs,
.visible-only-xs,
.visible-only-sm,
.visible-only-md,
.visible-only-lg {
	display: none;
}

@media (max-width: $bp-xxs - 1) {
	.visible-only-xxs {
		display: inline-block;
	}
}
@media screen and (min-width: $bp-xxs) and (max-width: $bp-xs - 1) {
	.visible-only-xs {
		display: inline-block;
	}
}
@media screen and (min-width: $bp-xs) and (max-width: $bp-sm - 1) {
	.visible-only-xs {
		display: inline-block;
	}
}
@media screen and (min-width: $bp-sm) and (max-width: $bp-md - 1) {
	.visible-only-sm {
		display: inline-block;
	}
}
@media screen and (min-width: $bp-md) and (max-width: $bp-lg - 1) {
	.visible-only-md {
		display: inline-block;
	}
}
.btn-mobile {
	display: none;
}
// Responsive fonts
@media (max-width: $bp-xs - 1) {
	.btn-desktop {
		display: none;
	}
	.btn-mobile {
		display: table;
	}
}
@media (max-width: $bp-xl - 1) {
	.flex-wrap-xl {
		flex-wrap: wrap;
	}
}
@media (max-width: $bp-lg - 1) {
	.flex-wrap-lg {
		flex-wrap: wrap;
	}
}
@media (max-width: $bp-md - 1) {
	.flex-wrap-md {
		flex-wrap: wrap;
	}
}
@media (max-width: $bp-sm - 1) {
	.flex-wrap-sm {
		flex-wrap: wrap;
	}
}
@media (max-width: $bp-xs - 1) {
	.flex-wrap-xs {
		flex-wrap: wrap;
	}
}
@media (max-width: $bp-xxs - 1) {
	.flex-wrap-xxs {
		flex-wrap: wrap;
	}
}
@media (max-width: $bp-xxxs - 1) {
	.flex-wrap-xxxs {
		flex-wrap: wrap;
	}
}
