.price-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: auto;
	padding: 20px;
	background-color: #fff;
	margin-bottom: 12px;
	border-radius: 10px;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
	position: relative;
	z-index: 2;
	h4 {
		font-weight: 700;
	}
}
