@import "../_variables.scss";
a.fill-link {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.btn-std {
	background-color: $base-color;
	color: $sec-color;
	font-weight: 700;
	display: table;
	line-height: 1;
	margin: 10px auto;
	padding: 16px 26px;
	text-align: center;
	max-width: 100%;
	border: none;
	border-radius: 8px;
	position: relative;
	z-index: 2;
	transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important;
	&.btn-reve {
		background: transparent;
		//color: $text;
	}
	&.btn-left {
		margin: 10px auto 10px 0;
	}
	&.btn-right {
		margin: 10px 0 10px auto;
	}
	&.btn-center {
		margin: 10px auto;
	}
	&:hover {
		background-color: $sec-color;
		box-shadow: inset 0 0 0 2px $base-color;
		color: $base-color;
	}
}

a.btn-arrow {
	padding-right: 25px;
	display: table;
	margin: 16px 0 0 auto;
	position: relative;
	font-weight: 700;

	&::before {
		position: absolute;
		content: "";
		width: 20px;
		height: 20px;
		background-image: url("../img/arrow-right.svg");
		right: 0px;
		top: 50%;
		transform: translate(0, -50%);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		transition: all 250ms ease-in-out;
	}
	&:hover {
		&::before {
			right: -5px;
		}
	}
}