@import '../variables';
.about-us-hp-wrapper {
	display: flex;
	.about-us {
		width: 55%;
		margin-right: 30px;
		img {
			border-radius: 10px;
			width: 100%;
			height: 100%;
			object-fit:cover;
		}
		h3 {
			position: relative;
			padding-bottom: 15px;
			font-weight: 700;
			margin-bottom: 25px;
			&::before {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;

				width: 50px;
				height: 3px;
				border-radius: 30px;
				background-color: $base-color;
			}
		}
		.text-strong {
			opacity: 1;
			font-weight: 700;
		}
	}
	.news-hp {
		width: 45%; 
	}
}
@media (max-width: $bp-md - 1) {
	.about-us-hp-wrapper {
		display: flex;
		flex-wrap: wrap;
		.about-us {
			width: 100%;
			margin-bottom: 30px;
			margin-right: 0;
			img {
				height: 350px;
				width: 100%;
				object-fit: cover;
			}
		}
		.news-hp {
			width: 100%;
		}
	}
}
